"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.FailedTypeCheck = exports.RequiresAuthentication = exports.RequiresDomain = exports.ApiClientError = void 0;
var tslib_1 = require("tslib");
var ApiClientError = /** @class */ (function (_super) {
    tslib_1.__extends(ApiClientError, _super);
    function ApiClientError(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, ApiClientError.prototype);
        return _this;
    }
    return ApiClientError;
}(Error));
exports.ApiClientError = ApiClientError;
var RequiresDomain = /** @class */ (function (_super) {
    tslib_1.__extends(RequiresDomain, _super);
    function RequiresDomain() {
        var _this = _super.call(this, 'Domain is not given but is required.') || this;
        Object.setPrototypeOf(_this, RequiresDomain.prototype);
        return _this;
    }
    return RequiresDomain;
}(ApiClientError));
exports.RequiresDomain = RequiresDomain;
var RequiresAuthentication = /** @class */ (function (_super) {
    tslib_1.__extends(RequiresAuthentication, _super);
    function RequiresAuthentication() {
        var _this = _super.call(this, 'Only users with a valid token can make this call.') || this;
        Object.setPrototypeOf(_this, RequiresAuthentication.prototype);
        return _this;
    }
    return RequiresAuthentication;
}(ApiClientError));
exports.RequiresAuthentication = RequiresAuthentication;
var FailedTypeCheck = /** @class */ (function (_super) {
    tslib_1.__extends(FailedTypeCheck, _super);
    function FailedTypeCheck(message) {
        var _this = _super.call(this, message) || this;
        Object.setPrototypeOf(_this, FailedTypeCheck.prototype);
        return _this;
    }
    return FailedTypeCheck;
}(ApiClientError));
exports.FailedTypeCheck = FailedTypeCheck;
