"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitSwipeInterview = exports.swipe = exports.fetchApplicationSwipePotentials = exports.fetchApplicationSwipeMatches = void 0;
var tslib_1 = require("tslib");
var fetch_media_1 = require("fetch-media");
var core_1 = require("./core");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
function fetchApplicationSwipeMatches(matchEvent, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!matchEvent) {
                        throw new errors_1.ApiClientError('Missing match event');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-swipe/" + matchEvent + "/matches", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationSwipeMatches = fetchApplicationSwipeMatches;
function fetchApplicationSwipePotentials(matchEvent, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!matchEvent) {
                        throw new errors_1.ApiClientError('Missing match event');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-swipe/" + matchEvent + "/potential", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationSwipePotentials = fetchApplicationSwipePotentials;
function swipe(matchEvent, matchId, kind, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!matchEvent) {
                        throw new errors_1.ApiClientError('Missing match event');
                    }
                    if (!matchId) {
                        throw new errors_1.ApiClientError('Missing match ID');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-swipe/" + matchEvent, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                                contentType: 'application/json; charset=utf-8',
                            },
                            method: 'POST',
                            signal: signal,
                            debug: debug,
                            body: {
                                eventSwipe: {
                                    targetEventGuestRef: {
                                        eventGuest: matchId,
                                        eventGuestId: matchId,
                                    },
                                    kind: kind,
                                },
                            },
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.swipe = swipe;
function submitSwipeInterview(matchEvent, answers, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var body, result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!matchEvent) {
                        throw new errors_1.ApiClientError('Missing match event');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    body = {
                        module: {
                            interview: {
                                answer: answers,
                            },
                        },
                    };
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-swipe/" + matchEvent + "/interview", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                                contentType: 'application/json; charset=utf-8',
                            },
                            method: 'POST',
                            signal: signal,
                            debug: debug,
                            body: body,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.submitSwipeInterview = submitSwipeInterview;
