"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerPushToken = void 0;
var tslib_1 = require("tslib");
var fetch_media_1 = require("fetch-media");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
function registerPushToken(endpoint, token, authorization, userId, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var urlData;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    urlData = userId
                        ? encodeURIComponent(token) + "/" + encodeURIComponent(userId)
                        : encodeURIComponent(token);
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/public/push-token/" + urlData, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    });
}
exports.registerPushToken = registerPushToken;
