import React from 'react';
import { Image, PixelRatio } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useDimensions } from './hooks/useDimensions';
export function ResponsiveImage({ round, ratio, src, mode, accessibilityLabel, maxWidth = 720, renderFallback, }) {
    const { roundness } = useTheme();
    const { width } = useDimensions();
    if (!src) {
        return renderFallback ? renderFallback() : null;
    }
    const actualWidth = Math.min(maxWidth, width);
    const height = PixelRatio.roundToNearestPixel(actualWidth / ratio);
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    return (React.createElement(Image, { source: { uri: src, width: actualWidth, height }, style: {
            width: actualWidth,
            height,
            borderTopLeftRadius: topRadius,
            borderTopRightRadius: topRadius,
            borderBottomLeftRadius: bottomRadius,
            borderBottomRightRadius: bottomRadius,
            overflow: 'hidden',
        }, resizeMode: mode, accessible: !!accessibilityLabel || undefined, accessibilityLabel: accessibilityLabel, accessibilityRole: "image" }));
}
