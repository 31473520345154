import { Platform } from 'react-native';
const IS_ANDROID = Platform.OS === 'android';
const DAY_NAMES = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const LONG_DAY_NAMES = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
const MONTH_NAMES = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
];
const DEFAULTS = {
    longDayNames: LONG_DAY_NAMES,
    shortDayNames: DAY_NAMES,
    monthNames: MONTH_NAMES,
};
export function localeLongWeekDayString(date, { longDayNames } = DEFAULTS) {
    if (IS_ANDROID) {
        return longDayNames[date.getDay()];
    }
    return date.toLocaleDateString(undefined, { weekday: 'long' });
}
export function localeWeekDayString(date, { shortDayNames } = DEFAULTS) {
    if (IS_ANDROID) {
        return shortDayNames[date.getDay()];
    }
    return date.toLocaleDateString(undefined, { weekday: 'short' });
}
export function localeDateString(date, showWeekDay = true, showYear = true, { shortDayNames, monthNames, } = DEFAULTS) {
    if (IS_ANDROID) {
        const weekDay = shortDayNames[date.getDay()];
        const month = monthNames[date.getMonth()];
        const day = date.getDate();
        const year = date.getFullYear();
        let result = showWeekDay ? `${weekDay}, ` : '';
        result = `${result}${day} ${month}`;
        return showYear ? `${result} ${year}` : result;
    }
    return date.toLocaleDateString(undefined, {
        day: 'numeric',
        year: showYear ? 'numeric' : undefined,
        month: 'long',
        weekday: showWeekDay ? 'short' : undefined,
    });
}
export function localeTimeString(date, showSeconds = false) {
    if (IS_ANDROID) {
        const hours = date.getHours();
        const minutes = date.getMinutes();
        const seconds = date.getSeconds();
        return `${hours < 10 ? '0' : ''}${hours}:${minutes < 10 ? '0' : ''}${minutes}${showSeconds ? `:${seconds < 10 ? '0' : ''}${seconds}` : ''}`;
    }
    return date.toLocaleTimeString(undefined, {
        hour: 'numeric',
        minute: 'numeric',
        hour12: false,
        second: showSeconds ? 'numeric' : undefined,
    });
}
