import React, { createContext, useContext, useMemo } from 'react';
export const DEFAULT_BLOCK_SETTINGS = {
    Advertisement: {
        ratio: [16, 9],
    },
    EventListItem: {
        type: 'description-focus',
        lines: 3,
        tagsEnabled: true,
        imageEnabled: true,
    },
};
const BlockSettingsContext = createContext(DEFAULT_BLOCK_SETTINGS);
export const BlockSettingsProvider = BlockSettingsContext.Provider;
export function useBlockSettings() {
    return useContext(BlockSettingsContext);
}
export function useHasProvidedBlockSettings() {
    return useBlockSettings() !== DEFAULT_BLOCK_SETTINGS;
}
export function ProvideBlockSettings({ settings, children, }) {
    const merged = useMemo(() => (Object.assign(Object.assign({}, DEFAULT_BLOCK_SETTINGS), settings)), [
        settings,
    ]);
    return (React.createElement(BlockSettingsProvider, { value: merged }, children));
}
