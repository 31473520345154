import React, { createContext, useContext, useMemo } from 'react';
export class BlockDataNotProvidedError extends Error {
    constructor(which) {
        super(`${which} has not been provided via a BlockDataProvider`);
        Object.setPrototypeOf(this, BlockDataNotProvidedError.prototype);
    }
}
export const DEFAULT_BLOCK_DATA = {
    getCheckinStatus: () => Promise.reject(new BlockDataNotProvidedError('getCheckinStatus')),
    setCheckinStatus: () => Promise.reject(new BlockDataNotProvidedError('setCheckinStatus')),
    getEventById: () => Promise.reject(new BlockDataNotProvidedError('getEventById')),
    getFirstVisibleEvent: () => Promise.reject(new BlockDataNotProvidedError('getFirstVisibleEvent')),
    getImageUrl: () => {
        console.error(new BlockDataNotProvidedError('getImageUrl'));
        return null;
    },
    getInfoById: () => Promise.reject(new BlockDataNotProvidedError('getInfoById')),
    getGameMapById: () => Promise.reject(new BlockDataNotProvidedError('getGameMapById')),
    getLocationById: () => Promise.reject(new BlockDataNotProvidedError('getLocationById')),
    getLocationEventsById: () => Promise.reject(new BlockDataNotProvidedError('getLocationEventsById')),
    getSponsorById: () => Promise.reject(new BlockDataNotProvidedError('getSponsorById')),
    getSponsor: () => Promise.reject(new BlockDataNotProvidedError('getSponsor')),
    getSubEventsById: () => Promise.reject(new BlockDataNotProvidedError('getSubEventsById')),
    getTimeslots: () => Promise.reject(new BlockDataNotProvidedError('getTimeslots')),
    getTimeslotsContent: () => Promise.reject(new BlockDataNotProvidedError('getTimeslotsContent')),
    setTimeslotRegistration: () => Promise.reject(new BlockDataNotProvidedError('setTimeslotRegistration')),
    getVideoPreviewById: () => Promise.reject(new BlockDataNotProvidedError('getVideoPreviewById')),
};
const BlockDataContext = createContext(DEFAULT_BLOCK_DATA);
export const BlockDataProvider = BlockDataContext.Provider;
export function useBlockData() {
    return useContext(BlockDataContext);
}
export function useHasProvidedBlockData() {
    return useBlockData() !== DEFAULT_BLOCK_DATA;
}
export function ProvideBlockData({ provider, children, }) {
    const merged = useMemo(() => (Object.assign(Object.assign({}, DEFAULT_BLOCK_DATA), provider)), [
        provider,
    ]);
    return React.createElement(BlockDataProvider, { value: merged }, children);
}
