"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitUserImage = exports.fetchApplicationUser = exports.fetchUser = void 0;
var tslib_1 = require("tslib");
var core_1 = require("./core");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
/**
 * Fetch the user associated with the current authorization
 *
 * @param endpoint the base endpoint
 * @param authorization the user authorization
 * @param signal abort signal
 * @param debug
 */
function fetchUser(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, core_1.fetchMedia(endpoint + '/user', {
                        headers: {
                            accept: ACCEPT,
                            authorization: authorization,
                        },
                        signal: signal,
                        debug: debug,
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchUser = fetchUser;
/**
 * Fetch the user associated with the current authorization
 *
 * @param endpoint the base endpoint
 * @param authorization the user authorization
 * @param signal abort signal
 * @param debug
 */
function fetchApplicationUser(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/application/user/profile", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationUser = fetchApplicationUser;
function submitUserImage(userId, data, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!userId) {
                        throw new errors_1.ApiClientError('user id missing');
                    }
                    if (!data.has('filepond')) {
                        throw new errors_1.ApiClientError("Expected multi-part form data with key \"filepond\"");
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/image/user/" + userId + "/image.profile", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            method: 'POST',
                            signal: signal,
                            debug: debug,
                            body: data,
                        })];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
exports.submitUserImage = submitUserImage;
