import React, { useCallback, useState } from 'react';
import { StyleSheet } from 'react-native';
import { Divider, IconButton, Menu } from 'react-native-paper';
import { useBlockNavigation } from './BlockNavigation';
import { PrimaryButton } from './Button';
export function DirectionsMenu({ label, icon, location, style, }) {
    const { gotoDirections } = useBlockNavigation();
    const [active, setActive] = useState(false);
    const toggleActive = useCallback(() => setActive((prev) => !prev), [
        setActive,
    ]);
    const gotoBike = useCallback(() => gotoDirections(location, 'bicycling'), [
        gotoDirections,
        location,
    ]);
    const gotoTransit = useCallback(() => gotoDirections(location, 'transit'), [
        gotoDirections,
        location,
    ]);
    const gotoWalk = useCallback(() => gotoDirections(location, 'walking'), [
        gotoDirections,
        location,
    ]);
    const openInMaps = useCallback(() => gotoDirections(location, 'none'), [
        gotoDirections,
        location,
    ]);
    return (React.createElement(Menu, { anchor: label || !icon ? (React.createElement(PrimaryButton, { icon: icon, onPress: toggleActive, style: style }, label)) : (React.createElement(IconButton, { style: { marginRight: 16 }, icon: icon, onPress: toggleActive, accessibilityLabel: "Toggle directions menu" })), visible: active, onDismiss: toggleActive },
        React.createElement(Menu.Item, { onPress: gotoWalk, title: "Walking", icon: "walk" }),
        React.createElement(Menu.Item, { onPress: gotoBike, title: "Biking", icon: "bike" }),
        React.createElement(Menu.Item, { onPress: gotoTransit, title: "Public Transit", icon: "subway-variant" }),
        React.createElement(Divider, { style: { height: StyleSheet.hairlineWidth } }),
        React.createElement(Menu.Item, { onPress: openInMaps, title: "See in maps", icon: "map-marker" })));
}
