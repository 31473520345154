import { Action } from '@introcloud/blocks';
import { createElement, useCallback } from 'react';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop, hasSurfacePadding, } from '../utils/hasSurface';
import { isHeading } from '../utils/isHeading';
export function ChatRoomBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { options: { spacing, mode, round, duration }, value: { kind, label }, previous, next, dragging, } = block;
    const shouldCollapseTop = !dragging && hasSurfacePadding(previous) && !isHeading(previous);
    const style = spacing
        ? {
            marginTop: shouldCollapseTop ? 0 : spacing * 8,
            marginBottom: spacing * 8,
        }
        : undefined;
    const timestamp = useInaccurateTimestamp({ every: 10 * 1000 });
    const currentlyActive = duration &&
        duration.start &&
        duration.end &&
        duration.start < timestamp &&
        duration.end > timestamp;
    return createElement(Action, {
        mode: mode || 'primary',
        icon: 'chat',
        label: label || 'Chat',
        surface: true,
        destination: { kind: 'chat', value: getChatRoomPlaceHolder(kind) },
        style,
        disabled: !currentlyActive,
        round: round
            ? {
                top: !extendsFullyToBottom(previous),
                bottom: !extendsFullyToTop(next),
            }
            : undefined,
        renderFallback,
    });
}
export function getChatRoomPlaceHolder(kind) {
    switch (kind) {
        case 'event': {
            return ':page';
        }
        case '1-on-1': {
            return ':page.:user';
        }
        case 'group': {
            return ':page.:group';
        }
    }
}
