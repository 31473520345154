"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.registerApplicationTimeslot = exports.fetchApplicationTimeslot = exports.fetchApplicationTimeslots = void 0;
var tslib_1 = require("tslib");
var fetch_media_1 = require("fetch-media");
var core_1 = require("./core");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
function fetchApplicationTimeslots(eventId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!eventId) {
                        throw new errors_1.ApiClientError('Missing event id to fetch timeslots for');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/timeslot/list/" + eventId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationTimeslots = fetchApplicationTimeslots;
function fetchApplicationTimeslot(timeslotId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!timeslotId) {
                        throw new errors_1.ApiClientError('Missing timeslot id to fetch');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/timeslot/" + timeslotId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationTimeslot = fetchApplicationTimeslot;
function registerApplicationTimeslot(timeslotId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!timeslotId) {
                        throw new errors_1.ApiClientError('Missing timeslot id to register for');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/timeslot/" + timeslotId + "/register", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.registerApplicationTimeslot = registerApplicationTimeslot;
