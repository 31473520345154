import { __rest } from "tslib";
import { PrimaryButton, useBlockDataTimeslots, useBlockNavigation, } from '@introcloud/blocks';
import { createElement, memo, useCallback } from 'react';
import { View } from 'react-native';
import { List, Surface, useTheme } from 'react-native-paper';
import { localeDateString } from 'react-native-time-helpers';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useErrorHandler } from '../ErrorHandler';
import { usePageData } from '../PageData';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
export function TimeSlotsBlock(block) {
    const { pageEvent } = usePageData();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { value: { label, event }, options, next, previous, } = block;
    const { round } = options || {};
    const eventId = (event === 'auto' ? pageEvent === null || pageEvent === void 0 ? void 0 : pageEvent._id : block.value.event) || '';
    const { data, change, refreshing } = useBlockDataTimeslots(eventId);
    const { roundness } = useTheme();
    const top = !extendsFullyToBottom(previous);
    const bottom = !extendsFullyToTop(next);
    if (!eventId || !data || data.timeslots.length === 0) {
        return renderFallback();
    }
    const style = round
        ? {
            borderTopLeftRadius: !label && top ? 0 : roundness,
            borderTopRightRadius: !label && !top ? 0 : roundness,
            borderBottomLeftRadius: !bottom ? 0 : roundness,
            borderBottomRightRadius: !bottom ? 0 : roundness,
        }
        : {};
    const anyCurrent = data.timeslots.find((timeslot) => timeslot.registeredEventGuest);
    return createElement(View, { style: { width: '100%' } }, [
        label
            ? createElement(List.Subheader, { key: 'header', style: { width: '100%' } }, label)
            : null,
        createElement(Surface, {
            key: 'list',
            style: [style, { elevation: 1 }],
            children: data.timeslots.map((timeslot) => {
                const isCurrent = (anyCurrent === null || anyCurrent === void 0 ? void 0 : anyCurrent._id) === timeslot._id;
                return createElement(TimeSlotListItem, {
                    key: timeslot._id,
                    timeslot,
                    isCurrent,
                    anyCurrent: !!anyCurrent,
                    change,
                    refreshing,
                });
            }),
        }),
    ]);
}
function TimeSlotListItem_(_a) {
    var { timeslot, isCurrent } = _a, props = __rest(_a, ["timeslot", "isCurrent"]);
    const timestamp = useInaccurateTimestamp({ every: 1000 * 10 });
    const isPast = timeslot.end.unix < timestamp;
    const description = timeslot.name.full
        ? `${timeslot.name.full} (${localeDateString(new Date(timeslot.start.unix), false, false)})`
        : localeDateString(new Date(timeslot.start.unix), true, false);
    // In the past, you can only access the data if you were a guest.
    if (isPast) {
        /*
        if (isCurrent) {
          return createElement(MoreTimeSlot, {
            timeslot,
            description,
            isCurrent,
            ...props,
          });
        }
        */
        return createElement(PastTimeSlot, Object.assign({ timeslot,
            description,
            isCurrent }, props));
    }
    const slotsLeft = timeslot.count.number - timeslot.registeredAmount;
    const isNowOrPast = timeslot.start.unix < timestamp;
    // If you're a guest, either wait or get access
    if (isCurrent) {
        if (isNowOrPast) {
            return createElement(MoreTimeSlot, Object.assign({ timeslot,
                description,
                isCurrent }, props));
        }
        return createElement(WaitTimeSlot, Object.assign({ timeslot,
            description,
            isCurrent }, props));
    }
    if (slotsLeft > 0) {
        return createElement(JoinTimeSlot, Object.assign({ timeslot,
            description,
            isCurrent }, props));
    }
    return createElement(FullTimeSlot, Object.assign({ timeslot,
        description,
        isCurrent }, props));
}
function MoreTimeSlot({ timeslot, refreshing, description, }) {
    const { gotoTimeslot } = useBlockNavigation();
    const hasPage = timeslot.pageRef && timeslot.pageRef.page;
    const actionText = hasPage ? 'More...' : 'Joined';
    const loading = refreshing === true || refreshing === timeslot._id;
    const onPress = useCallback(() => {
        gotoTimeslot(timeslot._id);
    }, [timeslot._id]);
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-marked-outline` })),
        right: (props) => createElement(PrimaryButton, {
            loading,
            children: actionText,
            onPress,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${timeslot.start.time} - ${timeslot.end.time}`,
        description,
    });
}
function PastTimeSlot({ timeslot, description }) {
    const actionText = 'Expired';
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-blank-outline` })),
        right: (props) => createElement(PrimaryButton, {
            disabled: true,
            children: actionText,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${timeslot.start.time} - ${timeslot.end.time}`,
        description,
    });
}
function WaitTimeSlot({ timeslot, description, refreshing, }) {
    const actionText = 'Joined';
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-marked-outline` })),
        right: (props) => createElement(PrimaryButton, {
            disabled: true,
            loading: refreshing === true || refreshing === timeslot._id,
            children: actionText,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${timeslot.start.time} - ${timeslot.end.time}`,
        description,
    });
}
function FullTimeSlot({ timeslot, description, refreshing, }) {
    const actionText = 'Full';
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-blank-outline` })),
        right: (props) => createElement(PrimaryButton, {
            disabled: true,
            loading: refreshing === true || refreshing === timeslot._id,
            children: actionText,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${timeslot.start.time} - ${timeslot.end.time}`,
        description,
    });
}
function JoinTimeSlot({ timeslot, description, refreshing, change, }) {
    const slotsLeft = timeslot.count.number - timeslot.registeredAmount;
    const actionText = `${slotsLeft} left`;
    const onPress = useCallback(() => {
        change(timeslot._id);
    }, [change, timeslot._id]);
    return createElement(List.Item, {
        left: (props) => createElement(List.Icon, Object.assign(Object.assign({}, props), { icon: `checkbox-blank-outline` })),
        right: (props) => createElement(PrimaryButton, {
            onPress,
            disabled: !!refreshing,
            loading: refreshing === true || refreshing === timeslot._id,
            children: actionText,
            style: { marginVertical: 'auto', alignSelf: 'center' },
        }),
        title: `${timeslot.start.time} - ${timeslot.end.time}`,
        description,
    });
}
const TimeSlotListItem = memo(TimeSlotListItem_);
