import React, { Fragment } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Caption, List, ProgressBar, Surface, useTheme, } from 'react-native-paper';
import { localeTimeString } from 'react-native-time-helpers';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { Spacer } from './Spacer';
import { localeSmartTimeString } from './utils';
export function TimeProgressBar({ from, to, }) {
    const now = useInaccurateTimestamp({
        every: Platform.select({ web: 10 * 1000, default: 1500 }),
    });
    const fromDate = new Date(from);
    const toDate = new Date(to);
    // Nothing to see
    if (from > now || to < now) {
        return (React.createElement(List.Item, { title: `${localeTimeString(fromDate)} - ${localeTimeString(toDate)}`, left: (props) => (React.createElement(List.Icon, Object.assign({ icon: "calendar-clock" }, props, { style: { margin: 0 } }))) }));
    }
    const size = to - from;
    const progress = now - from;
    const value = progress / size;
    return (React.createElement(View, { style: {
            marginLeft: 16,
            marginRight: 16,
            paddingBottom: 24,
            marginBottom: 12,
            position: 'relative',
        } },
        React.createElement(ProgressBar, { visible: true, indeterminate: false, progress: value, style: styles.progressDivider }),
        React.createElement(Caption, { style: {
                position: 'absolute',
                top: 3,
                left: 0,
                textAlign: 'left',
            } },
            "from ",
            localeTimeString(fromDate)),
        React.createElement(Caption, { style: {
                position: 'absolute',
                top: 3,
                right: 0,
                textAlign: 'right',
            } },
            "to ",
            localeTimeString(toDate))));
}
export function CardTimeProgressBar({ from, to, }) {
    const { roundness } = useTheme();
    const now = useInaccurateTimestamp({
        every: Platform.select({ web: 10 * 1000, default: 1500 }),
    });
    const fromDate = new Date(from);
    const toDate = new Date(to);
    // Nothing to see
    if (from > now || to < now) {
        const label = `${localeSmartTimeString(fromDate, now)} - ${localeSmartTimeString(toDate, now, fromDate)}`;
        return (React.createElement(Fragment, null,
            React.createElement(Surface, { style: {
                    elevation: 1,
                    borderBottomLeftRadius: roundness,
                    borderBottomRightRadius: roundness,
                } },
                React.createElement(List.Item, { left: (props) => (React.createElement(List.Icon, Object.assign({ icon: "calendar-clock" }, props, { style: [styles.icon] }))), title: label, titleStyle: styles.titleSecond })),
            React.createElement(Spacer, { space: 1 })));
    }
    const size = to - from;
    const progress = now - from;
    const value = progress / size;
    return (React.createElement(Fragment, null,
        React.createElement(Surface, { style: [
                styles.progressBar,
                {
                    borderBottomLeftRadius: roundness,
                    borderBottomRightRadius: roundness,
                },
            ] },
            React.createElement(Caption, { style: styles.progressFrom },
                "from ",
                localeTimeString(fromDate)),
            React.createElement(Caption, { style: styles.progressTo },
                "to ",
                localeTimeString(toDate)),
            React.createElement(View, { style: [
                    styles.progressOverlay,
                    {
                        borderBottomLeftRadius: roundness,
                        borderBottomRightRadius: roundness,
                    },
                ] },
                React.createElement(ProgressBar, { visible: true, indeterminate: false, progress: value, style: styles.progressDivider }))),
        React.createElement(Spacer, { space: 1 })));
}
const styles = StyleSheet.create({
    icon: { marginLeft: 0 },
    progressBar: {
        elevation: 1,
        marginLeft: 0,
        marginRight: 0,
        position: 'relative',
        height: 32,
        overflow: 'hidden',
    },
    progressFrom: {
        position: 'absolute',
        top: 0,
        left: 16,
        textAlign: 'left',
    },
    progressTo: {
        position: 'absolute',
        top: 0,
        right: 16,
        textAlign: 'right',
    },
    progressOverlay: {
        bottom: 0,
        left: 0,
        right: 0,
        height: 3,
        position: 'absolute',
        overflow: 'hidden',
    },
    progressDivider: {
        margin: 0,
        height: 3,
    },
    titleSecond: { paddingLeft: Platform.OS === 'web' ? 8 : 0 },
});
