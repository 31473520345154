"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchApplicationVisuals = exports.fetchApplication = void 0;
var tslib_1 = require("tslib");
var core_1 = require("./core");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
/**
 * Fetch the application
 *
 * @param endpoint the base endpoint
 * @param signal abort signal
 * @param debug
 */
function fetchApplication(endpoint, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/application", {
                            headers: {
                                accept: ACCEPT,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplication = fetchApplication;
/**
 * Fetch the application visuals
 *
 * @param endpoint the base endpoint
 * @param signal abort signal
 * @param debug
 */
function fetchApplicationVisuals(endpoint, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/application/visuals", {
                            headers: {
                                accept: ACCEPT,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationVisuals = fetchApplicationVisuals;
