import { __rest } from "tslib";
import { Table } from '@introcloud/blocks';
import { createElement, useCallback } from 'react';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
export function TableBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const _a = block.options, { round } = _a, options = __rest(_a, ["round"]), { value, previous, next } = block;
    if (options.columns === 0 || value.values.length === 0) {
        return renderFallback();
    }
    return createElement(Table, Object.assign(Object.assign(Object.assign({}, value), options), { round: round
            ? {
                top: !extendsFullyToBottom(previous),
                bottom: !extendsFullyToTop(next),
            }
            : undefined, renderFallback }));
}
