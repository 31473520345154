import React from 'react';
import { StyleSheet, PixelRatio, View } from 'react-native';
import { useTheme } from 'react-native-paper';
import { useDimensions } from './hooks/useDimensions';
export function ResponsiveIframe({ forceInline, round, ratio, src, maxWidth = 720, renderFallback, }) {
    const { roundness } = useTheme();
    const { width } = useDimensions();
    const { colors: { placeholder }, } = useTheme();
    if (!src) {
        return renderFallback ? renderFallback() : null;
    }
    const actualWidth = Math.min(maxWidth, width);
    const height = PixelRatio.roundToNearestPixel(actualWidth / ratio);
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
        width: actualWidth,
        height,
    };
    return (React.createElement(View, { style: [styles.wrapper, borderStyle] },
        React.createElement("iframe", { src: src, frameBorder: 0, style: Object.assign(Object.assign({}, borderStyle), { backgroundColor: placeholder, display: 'block', overflow: 'hidden' }), allowFullScreen: !forceInline, allow: `camera;microphone;autoplay;${forceInline ? '' : 'fullscreen;picture-in-picture;'}` })));
}
const styles = StyleSheet.create({
    wrapper: {
        overflow: 'hidden',
    },
});
