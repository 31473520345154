"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchApplicationLocation = exports.fetchApplicationLocations = exports.matchLocationOnId = exports.fetchEditorLocations = exports.fetchAdminLocation = exports.fetchAdminLocations = void 0;
var tslib_1 = require("tslib");
var fetch_media_1 = require("fetch-media");
var core_1 = require("./core");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
var CONTENT_TYPE = 'application/json; charset=utf-8';
function fetchAdminLocations(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/company/location/table", {
                        headers: {
                            accept: ACCEPT,
                            authorization: authorization,
                            contentType: CONTENT_TYPE,
                        },
                        body: {
                            search: { term: '' },
                            find: {},
                            populate: [],
                            sort: {},
                            select: {},
                            page: { items: 1000, current: 0 },
                        },
                        method: 'POST',
                        signal: signal,
                        debug: debug,
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchAdminLocations = fetchAdminLocations;
function fetchAdminLocation(locationId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!locationId) {
                        throw new errors_1.ApiClientError('Missing news location id to fetch');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/company/location/" + locationId + "/get", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchAdminLocation = fetchAdminLocation;
function fetchEditorLocations(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/editor/location", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchEditorLocations = fetchEditorLocations;
function matchLocationOnId(id, locations) {
    return locations.filter(function (location) { return location._id === id; });
}
exports.matchLocationOnId = matchLocationOnId;
function fetchApplicationLocations(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/location", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationLocations = fetchApplicationLocations;
function fetchApplicationLocation(locationId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!locationId) {
                        throw new errors_1.ApiClientError('Missing location id to fetch');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/location/" + locationId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationLocation = fetchApplicationLocation;
