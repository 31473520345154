import { __rest } from "tslib";
import { useMutation, useQuery, useQueryClient, } from 'react-query';
import { useBlockData } from '../BlockData';
export function useBlockDataTimeslots(eventId, userId, _a = {}, _b = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    var { onSettled } = _b, mutationOptions = __rest(_b, ["onSettled"]);
    const { getTimeslots, setTimeslotRegistration } = useBlockData();
    const queryClient = useQueryClient();
    const _c = useQuery(['blocks', 'event', eventId, 'timeslots', userId], () => getTimeslots(eventId), Object.assign({ enabled: enabled && !!eventId }, options)), { data: timeslots, error } = _c, others = __rest(_c, ["data", "error"]);
    const { mutateAsync, error: mutateError, isLoading: isMutating, } = useMutation(['blocks', 'event', eventId, 'timeslots', userId], setTimeslotRegistration, Object.assign(Object.assign({}, mutationOptions), { onSettled: (data, error, variables, context) => {
            queryClient.invalidateQueries([
                'blocks',
                'event',
                eventId,
                'timeslots',
                userId,
            ]);
            onSettled && onSettled(data, error, variables, context);
        } }));
    return {
        data: timeslots,
        loading: others.isLoading,
        refreshing: (others.isFetching && !others.isLoading) || isMutating,
        error: error || mutateError,
        reload: others.refetch,
        change: mutateAsync,
    };
}
export function useBlockDataTimeslot(timeslotId, userId, _a = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    const { getTimeslotsContent } = useBlockData();
    const _b = useQuery(['blocks', 'timeslot', timeslotId, userId], () => getTimeslotsContent(timeslotId), Object.assign({ enabled: enabled && !!timeslotId }, options)), { data: timeslot, error } = _b, others = __rest(_b, ["data", "error"]);
    return Object.assign({ data: timeslot, loading: others.isLoading, refreshing: others.isFetching && !others.isLoading, error, reload: others.refetch }, others);
}
