"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchApplicationPage = exports.fetchEditorPages = exports.fetchEditorPage = exports.patchEditorPage = exports.createEditorPage = exports.initializePage = exports.patchPage = exports.fetchAdminPage = exports.fetchAdminPagesIndex = void 0;
var tslib_1 = require("tslib");
var fetch_media_1 = require("fetch-media");
var core_1 = require("./core");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
var CONTENT_TYPE = 'application/json; charset=utf-8';
function fetchAdminPagesIndex(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/company/page/table", {
                        headers: {
                            accept: ACCEPT,
                            authorization: authorization,
                            contentType: CONTENT_TYPE,
                        },
                        body: {
                            search: { term: '' },
                            find: {},
                            populate: [],
                            sort: {},
                            select: {
                                eventRef: 1,
                                locationRef: 1,
                                name: 1,
                                _v: 1,
                                _id: 1,
                                subTitle: 1,
                            },
                            page: { items: 1000, current: 0 },
                        },
                        method: 'POST',
                        signal: signal,
                        debug: debug,
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchAdminPagesIndex = fetchAdminPagesIndex;
function fetchAdminPage(pageId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var url, result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pageId) {
                        throw new errors_1.ApiClientError('Missing page id to fetch');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    url = endpoint + "/company/page/" + pageId + "/get";
                    return [4 /*yield*/, fetch_media_1.fetchMedia(url, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchAdminPage = fetchAdminPage;
/**
 * @deprecated use pathEditorPage
 */
function patchPage(pageId, endpoint, authorization, page, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var url, result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pageId) {
                        throw new errors_1.ApiClientError('Missing page id to fetch');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    url = endpoint + "/company/page/" + pageId + "/update";
                    return [4 /*yield*/, fetch_media_1.fetchMedia(url, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                                contentType: CONTENT_TYPE,
                            },
                            method: 'PATCH',
                            body: page,
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.patchPage = patchPage;
/**
 * @deprecated use createEditorPage
 */
function initializePage(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var url, result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = endpoint + "/company/page/create";
                    return [4 /*yield*/, fetch_media_1.fetchMedia(url, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            method: 'GET',
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.initializePage = initializePage;
function createEditorPage(endpoint, authorization, page, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var url, result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    url = endpoint + "/application/editor/page/";
                    return [4 /*yield*/, fetch_media_1.fetchMedia(url, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                                contentType: CONTENT_TYPE,
                            },
                            method: 'POST',
                            body: page,
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.createEditorPage = createEditorPage;
function patchEditorPage(pageId, endpoint, authorization, page, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var url, result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pageId) {
                        throw new errors_1.ApiClientError('Missing page id to fetch');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    url = endpoint + "/application/editor/page/" + pageId;
                    return [4 /*yield*/, fetch_media_1.fetchMedia(url, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                                contentType: CONTENT_TYPE,
                            },
                            method: 'PATCH',
                            body: page,
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.patchEditorPage = patchEditorPage;
function fetchEditorPage(pageId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var url, result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pageId) {
                        throw new errors_1.ApiClientError('Missing page id to fetch');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    url = endpoint + "/application/editor/page/" + pageId;
                    return [4 /*yield*/, fetch_media_1.fetchMedia(url, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchEditorPage = fetchEditorPage;
function fetchEditorPages(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/editor/page", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchEditorPages = fetchEditorPages;
function fetchApplicationPage(pageId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!pageId) {
                        throw new errors_1.ApiClientError('Missing page id to fetch');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/page/" + pageId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationPage = fetchApplicationPage;
