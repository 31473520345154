import { localeDateString, localeTimeString, localeWeekDayString, localeLongWeekDayString, } from 'react-native-time-helpers';
export function rgb(color) {
    return `rgb(${color[0]}, ${color[1]}, ${color[2]})`;
}
export function rgba(color) {
    return `rgba(${color[0]}, ${color[1]}, ${color[2]}, ${color[3]})`;
}
const ONE_DAY = 1000 * 60 * 60 * 24;
const SIX_DAYS = ONE_DAY * 6;
export function localeSmartTimeString(date, now, relativeTo = null, long) {
    const compareDateString = localeDateString(date, false);
    // Same day as relative to, so don't need to repeat date
    if (relativeTo && compareDateString === localeDateString(relativeTo, false)) {
        return localeTimeString(date);
    }
    const nowDate = new Date(now);
    // Same day as now
    if (localeDateString(nowDate, false) === compareDateString) {
        return 'Today, ' + localeTimeString(date);
    }
    const unix = date.getTime();
    const recent = Math.abs(unix - now) < SIX_DAYS;
    const sameYear = date.getFullYear() === nowDate.getFullYear();
    // Within a week of now
    if (recent) {
        // Is the end date (and not the start date)
        if (relativeTo) {
            return `${localeWeekDayString(date)}, ${localeTimeString(date)}`;
        }
        const base = long
            ? localeLongWeekDayString(date)
            : localeWeekDayString(date);
        if (now < unix) {
            return `Next ${base}, ${localeTimeString(date)}`;
        }
        return `Last ${base}, ${localeTimeString(date)}`;
    }
    const showYear = !sameYear &&
        (!relativeTo || relativeTo.getFullYear() !== date.getFullYear());
    const showDay = !relativeTo || date.getUTCMonth() === relativeTo.getUTCMonth();
    return `${localeDateString(date, showDay, showYear)} ${localeTimeString(date)}`;
}
export function cleanTag(tag) {
    if (!tag) {
        return '';
    }
    return tag.trim().toLocaleLowerCase().replace(/ /g, '-');
}
export function normalizeTag(tag) {
    const result = tag.replace(/-/g, ' ');
    if (!result) {
        return '';
    }
    return result[0].toLocaleUpperCase() + result.slice(1).replace(/-/g, ' ');
}
