"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchChatConversationWithPageAsSolo = exports.fetchChatConversationWithPageAsPublic = exports.fetchChatConversationWithPageAsGroup = exports.fetchChatConversationWithGroup = exports.fetchChatConversationWithUser = exports.fetchChatProfile = exports.fetchChatInfo = exports.fetchChatName = void 0;
var tslib_1 = require("tslib");
var core_1 = require("./core");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
function fetchChatName(modelName, modelId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!modelName) {
                        throw new errors_1.ApiClientError('Missing chat model name');
                    }
                    if (!modelId) {
                        throw new errors_1.ApiClientError('Missing chat model id');
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/user/chat/name/" + modelName + "/" + modelId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchChatName = fetchChatName;
function fetchChatInfo(modelName, modelId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!modelName) {
                        throw new errors_1.ApiClientError('Missing chat model name');
                    }
                    if (!modelId) {
                        throw new errors_1.ApiClientError('Missing chat model id');
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/user/chat/info/" + modelName + "/" + modelId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchChatInfo = fetchChatInfo;
function fetchChatProfile(modelName, modelId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!modelName) {
                        throw new errors_1.ApiClientError('Missing chat model name');
                    }
                    if (!modelId) {
                        throw new errors_1.ApiClientError('Missing chat model id');
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/user/chat/profile/" + modelName + "/" + modelId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchChatProfile = fetchChatProfile;
function fetchChatConversationWithUser(userId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!userId) {
                        throw new errors_1.ApiClientError('Missing main event id');
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/user/chat/user/" + userId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchChatConversationWithUser = fetchChatConversationWithUser;
function fetchChatConversationWithGroup(groupId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!groupId) {
                        throw new errors_1.ApiClientError('Missing group id');
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/user/chat/group/" + groupId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchChatConversationWithGroup = fetchChatConversationWithGroup;
function fetchChatConversationWithPageAsGroup(pageId, groupId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!pageId) {
                        throw new errors_1.ApiClientError('Missing page id');
                    }
                    if (!groupId) {
                        throw new errors_1.ApiClientError('Missing group id');
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/user/chat/page/" + pageId + "/group/" + groupId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchChatConversationWithPageAsGroup = fetchChatConversationWithPageAsGroup;
function fetchChatConversationWithPageAsPublic(pageId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!pageId) {
                        throw new errors_1.ApiClientError('Missing main event id');
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/user/chat/page/" + pageId + "/everyone", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchChatConversationWithPageAsPublic = fetchChatConversationWithPageAsPublic;
function fetchChatConversationWithPageAsSolo(pageId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!pageId) {
                        throw new errors_1.ApiClientError('Missing main event id');
                    }
                    return [4 /*yield*/, core_1.fetchMedia(endpoint + "/user/chat/page/" + pageId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchChatConversationWithPageAsSolo = fetchChatConversationWithPageAsSolo;
