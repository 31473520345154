import { __rest } from "tslib";
import React from 'react';
import { StyleSheet, View } from 'react-native';
import { Surface, useTheme } from 'react-native-paper';
import { AccentButton, PrimaryButton, TextButton } from './Button';
import { useDestination } from './hooks/useDestination';
export function Action(_a) {
    var { surface, round, inset, renderFallback } = _a, props = __rest(_a, ["surface", "round", "inset", "renderFallback"]);
    const { roundness } = useTheme();
    if (!props.label) {
        return renderFallback ? renderFallback() : null;
    }
    if (surface) {
        const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
        const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
        return (React.createElement(Surface, { style: [
                styles.surface,
                {
                    borderTopLeftRadius: topRadius,
                    borderTopRightRadius: topRadius,
                    borderBottomLeftRadius: bottomRadius,
                    borderBottomRightRadius: bottomRadius,
                },
            ] },
            React.createElement(Action, Object.assign({}, props, { inset: inset, surface: false }))));
    }
    if (inset) {
        return (React.createElement(View, { style: styles.inset },
            React.createElement(Action, Object.assign({}, props, { inset: false }))));
    }
    return React.createElement(ActionButton, Object.assign({}, props, { style: [styles.button, props.style] }));
}
function ActionButton(_a) {
    var { mode, destination, label } = _a, other = __rest(_a, ["mode", "destination", "label"]);
    const onPress = useDestination(destination);
    switch (mode) {
        case 'primary': {
            return (React.createElement(PrimaryButton, Object.assign({}, other, { onPress: onPress }), label));
        }
        case 'accent': {
            return (React.createElement(AccentButton, Object.assign({}, other, { onPress: onPress }), label));
        }
        case 'text': {
            return (React.createElement(TextButton, Object.assign({}, other, { onPress: onPress }), label));
        }
        default: {
            return (React.createElement(PrimaryButton, Object.assign({}, other, { onPress: onPress }), label));
        }
    }
}
const styles = StyleSheet.create({
    surface: {
        elevation: 1,
        paddingHorizontal: 16,
    },
    inset: {
        marginHorizontal: 16,
    },
    button: { marginRight: 'auto' },
});
