var _a, _b;
import { StoreDownload } from '@introcloud/blocks';
import Constants from 'expo-constants';
import { createElement } from 'react';
import { useIsMobileView } from '../../utils/useIsMobileView';
const ANDROID_URL = (_a = Constants.manifest.extra) === null || _a === void 0 ? void 0 : _a.playStoreUrl;
const IOS_URL = (_b = Constants.manifest.extra) === null || _b === void 0 ? void 0 : _b.appStoreUrl;
export function StoreLinksBlock({ value: { label: title, androidUrlOverride, iosUrlOverride }, }) {
    const androidUrl = androidUrlOverride || ANDROID_URL;
    const iosUrl = iosUrlOverride || IOS_URL;
    const isMobileView = useIsMobileView();
    return createElement(StoreDownload, {
        iosUrl,
        androidUrl,
        isMobileView,
        title: title, // upstream type is incorrect,
    });
}
