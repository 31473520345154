"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TextError = exports.Problem = exports.StructuredErrors = exports.JsonError = exports.MediaTypeUnsupported = exports.NoRequestContentType = exports.NoResponseContentType = exports.FetchMediaError = exports.fetchMedia = exports.setDefaultHeaders = exports.setDefaultAccept = exports.ACCEPT_PROBLEM = void 0;
var tslib_1 = require("tslib");
var MEDIA_PROBLEM = 'application/problem+json';
var MEDIA_JSON_SUFFIX = '+json';
var MEDIA_JSON = 'application/json';
var MEDIA_GENERIC_BIN = 'application/octet-stream';
var MEDIA_TEXT_GROUP = 'text/';
var MEDIA_IMAGE_GROUP = 'image/';
var MEDIA_AUDIO_GROUP = 'audio/';
var MEDIA_VIDEO_GROUP = 'video/';
var MEDIA_FORM_DATA = 'multipart/form-data';
var MEDIA_FORM_URL_ENCODED = 'application/x-www-form-urlencoded';
var CUSTOM_ERROR = /application\/vnd\.(.+?)\.errors(?:\.v1[0-9]*)\+json/;
exports.ACCEPT_PROBLEM = MEDIA_PROBLEM + '; q=0.1';
var AcceptRef = { current: [exports.ACCEPT_PROBLEM] };
var HeadersRef = {
    current: {},
};
function setDefaultAccept() {
    var accept = [];
    for (var _i = 0; _i < arguments.length; _i++) {
        accept[_i] = arguments[_i];
    }
    AcceptRef.current = accept.slice();
}
exports.setDefaultAccept = setDefaultAccept;
function setDefaultHeaders(headers) {
    HeadersRef.current = headers || {};
}
exports.setDefaultHeaders = setDefaultHeaders;
function remapHeaders(headers) {
    return Object.keys(headers).reduce(function (result, header) {
        var mapped = header.replace(/[A-Z]/g, function (m) { return '-' + m.toLocaleLowerCase(); });
        var value = headers[header];
        if (value) {
            result[mapped] = value;
        }
        return result;
    }, {});
}
var debug = typeof console === 'object'
    ? 'debug' in console
        ? console.debug.bind(console)
        : console.log.bind(console)
    : process.stdout.write.bind(process.stdout);
var DEBUG_BEFORE = function (_a) {
    var method = _a.method, url = _a.url, accept = _a.accept, contentType = _a.contentType, headers = _a.headers, encodedBody = _a.encodedBody;
    debug(method + " " + url);
    debug('> accept', accept);
    (contentType || encodedBody) && debug('> body of', contentType);
    debug('> headers', headers);
    encodedBody && debug('> body', encodedBody);
};
var DEBUG_AFTER = function (_a) {
    var status = _a.status, url = _a.url, contentType = _a.contentType, headers = _a.headers;
    debug("< [" + status + "] " + url);
    contentType && debug('< body of', contentType);
    var logHeaders = {};
    headers.forEach(function (val, key) {
        return (logHeaders[key] = logHeaders[key] ? logHeaders[key] + ", " + val : val);
    });
    debug('< headers', logHeaders);
};
/**
 * Fetches media from a URL
 *
 * - Automatically encodes the request body if the contentType is a JSON type
 * - Automatically decodes the response body
 *    - as parsed JSON if it's JSON
 *    - as string if it's text
 *    - as ArrayBuffer or Blob if it's binary
 *    - as FormData if it's multipart/form-data
 *    - as UrlSearchParams if it has a body of url encoded form data
 * - Automatically parses errors, problems, structured errors, etc.
 *
 * @see MediaOptions
 *
 * @param url the fully qualified url to fetch from
 * @param param1 the {MediaOptions}
 * @returns A fetch promise
 */
function fetchMedia(url, _a) {
    var _b = _a.headers, accept = _b.accept, otherHeaders = tslib_1.__rest(_b, ["accept"]), _c = _a.method, method = _c === void 0 ? 'GET' : _c, body = _a.body, signal = _a.signal, debug = _a.debug, _d = _a.hooks, _e = _d === void 0 ? {
        before: debug ? DEBUG_BEFORE : undefined,
        after: debug ? DEBUG_AFTER : undefined,
    } : _d, _f = _e.before, before = _f === void 0 ? debug ? DEBUG_BEFORE : undefined : _f, _g = _e.after, after = _g === void 0 ? debug ? DEBUG_AFTER : undefined : _g, disableJson = _a.disableJson, disableText = _a.disableText, disableFormData = _a.disableFormData, disableFormUrlEncoded = _a.disableFormUrlEncoded, handleBinary = _a.handleBinary;
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var headers, contentType, encodedBody, response, responseContentType, responseOrError_1, errorContentType;
        return tslib_1.__generator(this, function (_h) {
            switch (_h.label) {
                case 0:
                    headers = tslib_1.__assign(tslib_1.__assign(tslib_1.__assign({}, HeadersRef.current), { accept: [accept, AcceptRef.current].join(', ') }), remapHeaders(otherHeaders));
                    contentType = headers['content-type'];
                    encodedBody = encodeBody(body, contentType);
                    before === null || before === void 0 ? void 0 : before({ method: method, url: url, accept: accept, contentType: contentType, headers: headers, encodedBody: encodedBody });
                    if (body && !contentType && !(body instanceof FormData)) {
                        throw new NoRequestContentType(url, new Response(undefined, { status: 400 }));
                    }
                    _h.label = 1;
                case 1:
                    _h.trys.push([1, 3, , 4]);
                    return [4 /*yield*/, fetch(url, {
                            headers: headers,
                            method: method,
                            body: encodeBody(body, contentType, debug),
                            signal: signal,
                        })];
                case 2:
                    response = _h.sent();
                    // Forward response to error
                    if (!response.ok || response.status >= 400) {
                        // eslint-disable-next-line @typescript-eslint/no-throw-literal
                        throw response;
                    }
                    responseContentType = response.headers.get('content-type');
                    if (!responseContentType) {
                        throw new NoResponseContentType(url, response);
                    }
                    after === null || after === void 0 ? void 0 : after({
                        url: response.url,
                        status: response.status,
                        headers: response.headers,
                        contentType: responseContentType,
                    });
                    // The response is json
                    if ((!disableJson && responseContentType.includes(MEDIA_JSON_SUFFIX)) ||
                        responseContentType.startsWith(MEDIA_JSON)) {
                        return [2 /*return*/, response.json()];
                    }
                    // The response is text
                    if (!disableText && responseContentType.startsWith(MEDIA_TEXT_GROUP)) {
                        return [2 /*return*/, response.text()];
                    }
                    // The response is binary
                    if (handleBinary &&
                        (responseContentType.startsWith(MEDIA_IMAGE_GROUP) ||
                            responseContentType.startsWith(MEDIA_AUDIO_GROUP) ||
                            responseContentType.startsWith(MEDIA_VIDEO_GROUP) ||
                            responseContentType.startsWith(MEDIA_GENERIC_BIN))) {
                        return [2 /*return*/, handleBinary === 'array-buffer'
                                ? response.arrayBuffer()
                                : response.blob()];
                    }
                    // The response is form data
                    if (!disableFormData && responseContentType.startsWith(MEDIA_FORM_DATA)) {
                        return [2 /*return*/, response.formData()];
                    }
                    // The response is url encoded form data (in the body)
                    if (!disableFormUrlEncoded &&
                        responseContentType.startsWith(MEDIA_FORM_URL_ENCODED)) {
                        return [2 /*return*/, response.text().then(function (result) { return new URLSearchParams(result); })];
                    }
                    // The response has unsupported data
                    throw new MediaTypeUnsupported(url, response, accept, responseContentType);
                case 3:
                    responseOrError_1 = _h.sent();
                    if (responseOrError_1 instanceof Error) {
                        return [2 /*return*/, Promise.reject(responseOrError_1)];
                    }
                    if (responseOrError_1 instanceof Response) {
                        errorContentType = responseOrError_1.headers.get('content-type');
                        after === null || after === void 0 ? void 0 : after({
                            url: responseOrError_1.url,
                            status: responseOrError_1.status,
                            headers: responseOrError_1.headers,
                            contentType: errorContentType,
                        });
                        // It's a problem
                        if (errorContentType.startsWith(MEDIA_PROBLEM)) {
                            return [2 /*return*/, responseOrError_1.json().then(function (response_1) {
                                    return Promise.reject(new Problem(responseOrError_1, response_1));
                                })];
                        }
                        // It's a structured error
                        if (CUSTOM_ERROR.test(errorContentType)) {
                            return [2 /*return*/, responseOrError_1.json().then(function (response_2) {
                                    return Promise.reject(new StructuredErrors(responseOrError_1, response_2));
                                })];
                        }
                        // It's a generic json error
                        if (errorContentType.startsWith(MEDIA_JSON)) {
                            return [2 /*return*/, responseOrError_1.json().then(function (response_3) {
                                    // Test if it can be coerced into a structured error
                                    if (typeof response_3 === 'object' &&
                                        response_3 !== null &&
                                        response_3.hasOwnProperty('errors') &&
                                        Array.isArray(response_3['errors']) &&
                                        (response_3['errors'].length === 0 ||
                                            (typeof response_3['errors'][0] === 'object' &&
                                                response_3['errors'][0] !== null &&
                                                response_3['errors'][0].hasOwnProperty('message')))) {
                                        return Promise.reject(new StructuredErrors(responseOrError_1, response_3));
                                    }
                                    return Promise.reject(new JsonError(responseOrError_1, response_3));
                                })];
                        }
                        if (errorContentType.startsWith(MEDIA_TEXT_GROUP)) {
                            return [2 /*return*/, responseOrError_1.text().then(function (response_4) {
                                    return Promise.reject(new TextError(responseOrError_1, response_4));
                                })];
                        }
                        // It's an error-response but not machine readable
                        return [2 /*return*/, Promise.reject(new MediaTypeUnsupported(url, responseOrError_1, [
                                'application/vnd.<vendor>.errors[.v<version>]+json',
                                exports.ACCEPT_PROBLEM,
                            ].join(', '), errorContentType))];
                    }
                    return [2 /*return*/, Promise.reject(new Error("Unknown issue occurred. Not an Error or Response, but " + responseOrError_1))];
                case 4: return [2 /*return*/];
            }
        });
    });
}
exports.fetchMedia = fetchMedia;
function encodeBody(data, contentType, debug) {
    if (contentType === undefined) {
        return data;
    }
    if (contentType.includes(MEDIA_JSON_SUFFIX) ||
        contentType.startsWith(MEDIA_JSON)) {
        return JSON.stringify(data, undefined, debug ? 2 : undefined);
    }
    return data;
}
var FetchMediaError = /** @class */ (function (_super) {
    tslib_1.__extends(FetchMediaError, _super);
    function FetchMediaError(message, response) {
        var _this = _super.call(this, message) || this;
        _this.response = response;
        Object.setPrototypeOf(_this, FetchMediaError.prototype);
        return _this;
    }
    return FetchMediaError;
}(Error));
exports.FetchMediaError = FetchMediaError;
/**
 * When using fetch-media, the response is automatically parsed based on its
 * Content-Type. If the response has no Content-Type, then the response can not
 * be parsed.
 *
 * Catch this error and use the response property to manually handle such
 * responses.
 */
var NoResponseContentType = /** @class */ (function (_super) {
    tslib_1.__extends(NoResponseContentType, _super);
    function NoResponseContentType(url, response) {
        var _this = _super.call(this, "\n      A request to " + url + " yielded a response (" + response.status + ": " + response.statusText + ") without a Content-Type.\n    ", response) || this;
        _this.url = url;
        Object.setPrototypeOf(_this, NoResponseContentType.prototype);
        return _this;
    }
    return NoResponseContentType;
}(FetchMediaError));
exports.NoResponseContentType = NoResponseContentType;
/**
 * When using fetch-media, the request can include a body that automatically is
 * converted to the correct format. In order for this to work, you MUST always
 * include a Content-Type in the request, when passing in a body. Here are the
 * common types supported:
 *
 * - String           : text/*
 * - StructuredData   : application/json application/vnd.<vendor>*+json
 * - Blob             : image/* video/* audio/* application/*
 * - BufferSource     : image/* video/* audio/* application/*
 * - FormData         : multipart/form-data
 * - URLSearchParams  : application/x-www-form-urlencoded
 * - ReadableStream<Uint8Array> : image/* video/* audio/* application/*
 *
 * Note that this is the list of types for content types when SENDING data. When
 * receiving data, currently it only automatically handles text, FormData, JSON,
 * URL Encoded data and binary, where all binary types are converted to
 * ArrayBuffer or Blob.
 */
var NoRequestContentType = /** @class */ (function (_super) {
    tslib_1.__extends(NoRequestContentType, _super);
    function NoRequestContentType(url, response) {
        var _this = _super.call(this, "\n      A request to " + url + " wanted to include a body, but a Content-Type has not been given. Add a Content-Type.\n    ", response) || this;
        _this.url = url;
        Object.setPrototypeOf(_this, NoResponseContentType.prototype);
        return _this;
    }
    return NoRequestContentType;
}(FetchMediaError));
exports.NoRequestContentType = NoRequestContentType;
/**
 * When using fetch-media, the response can include a body that automatically is
 * converted from the correct format. In order for this to work, the response
 * MUST always include a Content-Type in the response, when a body is present.
 * Here are the common types supported:
 *
 * - text/*                                           : .text()
 * - application/json application/vnd.<vendor>*+json  : .json()
 * - image/* video/* audio/* application/octet-stream : .arrayBuffer() / .blob()
 * - multipart/form-data                              : .formData()
 * - application/x-www-form-urlencoded                : new URLSearchParams()
 *
 * Note that this is the list of types for content types when RECEIVING data.
 * When sending data, it follows the limitations of fetch, but also converts
 * structured data to a JSON string.
 */
var MediaTypeUnsupported = /** @class */ (function (_super) {
    tslib_1.__extends(MediaTypeUnsupported, _super);
    function MediaTypeUnsupported(url, response, accept, contentType) {
        var _this = _super.call(this, "\n      A request to " + url + " yielded a response (" + response.status + ": " + response.statusText + ")\n      with a Content-Type that is unsupported. The original request expected:\n\n      " + accept + "\n\n      The final response reports as " + contentType + ".\n    ", response) || this;
        _this.url = url;
        _this.accept = accept;
        _this.contentType = contentType;
        Object.setPrototypeOf(_this, MediaTypeUnsupported.prototype);
        return _this;
    }
    return MediaTypeUnsupported;
}(FetchMediaError));
exports.MediaTypeUnsupported = MediaTypeUnsupported;
var JsonError = /** @class */ (function (_super) {
    tslib_1.__extends(JsonError, _super);
    function JsonError(response, data) {
        var _this = _super.call(this, JsonError.getError(data), response) || this;
        _this.data = data;
        Object.setPrototypeOf(_this, JsonError.prototype);
        return _this;
    }
    JsonError.getError = function (data) {
        // Common error keys
        var result = data['message'] ||
            data['error'] ||
            data['details'] ||
            data['title'] ||
            data['errors'];
        if (!result) {
            return "Tried to extract error from JSON, looking for 'message', 'error', 'details', 'title' and 'errors, buy found " + Object.keys(data) + ".";
        }
        if (Array.isArray(result)) {
            return result.join(', ');
        }
        return result;
    };
    return JsonError;
}(FetchMediaError));
exports.JsonError = JsonError;
var StructuredErrors = /** @class */ (function (_super) {
    tslib_1.__extends(StructuredErrors, _super);
    function StructuredErrors(response, data) {
        var _this = _super.call(this, StructuredErrors.getError(data), response) || this;
        _this.data = data;
        Object.setPrototypeOf(_this, StructuredErrors.prototype);
        return _this;
    }
    StructuredErrors.getError = function (data) {
        return data['errors']
            .map(function (_a) {
            var message = _a.message;
            return message;
        })
            .join(', ');
    };
    return StructuredErrors;
}(FetchMediaError));
exports.StructuredErrors = StructuredErrors;
var Problem = /** @class */ (function (_super) {
    tslib_1.__extends(Problem, _super);
    function Problem(response, data) {
        var _this = _super.call(this, Problem.getError(data), response) || this;
        _this.data = data;
        Object.setPrototypeOf(_this, Problem.prototype);
        return _this;
    }
    Problem.getError = function (data) {
        return data.detail;
    };
    return Problem;
}(FetchMediaError));
exports.Problem = Problem;
var TextError = /** @class */ (function (_super) {
    tslib_1.__extends(TextError, _super);
    function TextError(response, data) {
        var _this = _super.call(this, TextError.getError(response, data), response) || this;
        _this.data = data;
        Object.setPrototypeOf(_this, TextError.prototype);
        return _this;
    }
    TextError.getError = function (response, data) {
        return "[" + response.status + "] " + response.url + "\n    Expected a structured error or problem, but got text:\n    " + data;
    };
    return TextError;
}(FetchMediaError));
exports.TextError = TextError;
