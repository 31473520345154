import { LinearGradient } from 'expo-linear-gradient';
import React, { Fragment, useMemo } from 'react';
import { Image, StyleSheet, View } from 'react-native';
import { Caption, Card, IconButton, List, Title, useTheme, } from 'react-native-paper';
import { useDestination } from './hooks/useDestination';
import { useWindowWidth } from './hooks/useDimensions';
export function ActionGrid({ title, actions, mode, size, resizeMode, renderFallback, }) {
    if (actions.length === 0) {
        return renderFallback ? renderFallback() : null;
    }
    return (React.createElement(Fragment, null,
        title ? (React.createElement(View, { style: { width: '100%' } },
            React.createElement(List.Subheader, null, title))) : null,
        mode === 'images' && (React.createElement(ImageGrid, { resizeMode: resizeMode, size: size, actions: actions })),
        mode === 'icons' && React.createElement(IconGrid, { size: size, actions: actions })));
}
function ImageGrid({ actions, resizeMode, size, }) {
    const { colors: { placeholder, surface }, roundness, } = useTheme();
    const windowWidth = useWindowWidth();
    const columns = size === 'small' ? 3 : 2;
    const width = Math.floor((Math.min(720, windowWidth) - 17) / columns - 16);
    const empty = useMemo(() => {
        const result = [];
        const remainder = columns - ((actions.length % columns) % columns);
        if (remainder === columns) {
            return result;
        }
        for (let i = 0; i < remainder; i++) {
            result.push(Math.random().toString(36));
        }
        return result;
    }, [actions.length, columns]);
    return (React.createElement(View, { style: styles.gridItemWrapper },
        actions.map((action, index) => {
            return (React.createElement(PureImageGridItem, { action: action, key: index, placeholder: placeholder, size: width, roundness: roundness, resizeMode: resizeMode, surface: surface }));
        }),
        empty.map((none) => (React.createElement(View, { style: { width, height: width }, key: none })))));
}
const SCRIM_GRADIENT = [
    'transparent',
    'transparent',
    'rgba(0, 0, 0, .3)',
    'rgba(0, 0, 0, .6)',
];
const SCRIM_INVERSE_GRADIENT = [
    'transparent',
    'transparent',
    'rgba(255, 255, 255, .3)',
    'rgba(255, 255, 255, .6)',
];
export function ImageCard({ action, surface, placeholder, size, roundness, resizeMode, }) {
    const onPress = useDestination(action.destination);
    const sizeStyle = {
        width: size,
        height: size,
    };
    const backgroundStyle = action.backgroundColor
        ? { backgroundColor: action.backgroundColor }
        : {};
    return (React.createElement(Card, { style: [styles.imageCard, sizeStyle, backgroundStyle], onPress: onPress, accessible: true, accessibilityRole: action.destination.value ? 'imagebutton' : 'image', accessibilityLabel: action.accessibilityLabel || action.label }, resizeMode === 'contain' ? (React.createElement(ContainedImage, { action: action, size: size, surface: surface, placeholder: placeholder, roundness: roundness })) : (React.createElement(CoveredImage, { action: action, size: size, surface: surface, roundness: roundness, placeholder: placeholder }))));
}
function ContainedImage({ action, size, roundness, surface, placeholder, }) {
    const label = (action.label || '').trim();
    const source = typeof action.image === 'number'
        ? action.image
        : {
            uri: resizeImageUrl(action.image),
            width: size - 32,
            height: size - 32,
        };
    return (React.createElement(Card.Content, { style: { marginVertical: 16 } },
        React.createElement(Image, { resizeMode: "contain", source: source, style: {
                backgroundColor: action.image ? surface : placeholder,
                borderRadius: roundness,
            } }),
        label ? (React.createElement(Fragment, null,
            React.createElement(LinearGradient, { colors: SCRIM_INVERSE_GRADIENT, style: [StyleSheet.absoluteFillObject, { borderRadius: roundness }] }),
            React.createElement(Title, { style: styles.imageContainLabel2 }, label))) : null));
}
function CoveredImage({ action, size, surface, placeholder, roundness, }) {
    const label = (action.label || '').trim();
    const source = typeof action.image === 'number'
        ? action.image
        : {
            uri: resizeImageUrl(action.image),
            width: size,
            height: size,
        };
    return (React.createElement(Fragment, null,
        React.createElement(Image, { resizeMode: "cover", source: source, style: {
                backgroundColor: action.image ? surface : placeholder,
                borderRadius: roundness,
            } }),
        label ? (React.createElement(Fragment, null,
            React.createElement(LinearGradient, { colors: SCRIM_GRADIENT, style: [StyleSheet.absoluteFillObject, { borderRadius: roundness }] }),
            React.createElement(Title, { style: styles.imageCoverLabel }, label))) : null));
}
function IconGrid({ actions, size, }) {
    const windowWidth = useWindowWidth();
    const columns = size === 'small' ? 3 : 2;
    const width = Math.floor((Math.min(720, windowWidth) - 17) / columns - 16);
    const empty = useMemo(() => {
        const result = [];
        const remainder = columns - ((actions.length % columns) % columns);
        if (remainder === columns) {
            return result;
        }
        for (let i = 0; i < remainder; i++) {
            result.push(Math.random().toString(36));
        }
        return result;
    }, [actions.length, columns]);
    return (React.createElement(View, { style: styles.iconGrid },
        actions.map((action, index) => {
            return React.createElement(PureIconGridItem, { action: action, key: index, size: width });
        }),
        empty.map((none) => (React.createElement(View, { style: { width, height: width }, key: none })))));
}
function resizeImageUrl(url) {
    if (!url) {
        return url;
    }
    if (!url.startsWith('https://api.tactile.events/api/image/')) {
        return url;
    }
    return url.replace('/icon_1440', '/icon_512');
}
export function IconCard({ size, action }) {
    const onPress = useDestination(action.destination);
    return (React.createElement(Card, { style: [
            styles.iconCard,
            {
                width: size,
                height: size,
            },
        ], onPress: onPress },
        React.createElement(View, { style: styles.iconInner },
            React.createElement(IconButton, { icon: action.icon || 'link-variant', size: 48, onPress: onPress }),
            React.createElement(Caption, { style: styles.iconCaption }, action.label))));
}
const PureIconGridItem = React.memo(IconCard);
const PureImageGridItem = React.memo(ImageCard);
const styles = StyleSheet.create({
    gridItemWrapper: {
        flexWrap: 'wrap',
        paddingHorizontal: 8,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
    },
    imageCard: {
        margin: 0,
        marginBottom: 16,
    },
    imageContainLabel2: {
        color: 'black',
        bottom: 0,
        top: undefined,
        left: 0,
        right: 0,
        position: 'absolute',
        maxHeight: '100%',
        overflow: 'hidden',
        paddingBottom: 6,
        paddingLeft: 24,
    },
    imageCoverLabel: {
        color: 'white',
        padding: 8,
        bottom: 0,
        top: undefined,
        left: 0,
        right: 0,
        position: 'absolute',
        maxHeight: '100%',
        overflow: 'hidden',
        paddingLeft: 12,
    },
    imageContainLabel: {
        color: 'black',
        textShadowColor: 'rgba(255, 255, 255, .56)',
        textShadowOffset: { width: 1, height: 1 },
        textShadowRadius: 2,
        position: 'absolute',
        maxHeight: '100%',
        overflow: 'hidden',
        bottom: 0,
        left: 0,
        right: 0,
        top: undefined,
        paddingBottom: 18,
        paddingLeft: 24,
    },
    iconCard: {
        margin: 0,
        marginBottom: 16,
    },
    iconCaption: {
        marginTop: -14,
        textAlign: 'center',
        lineHeight: 12,
        maxWidth: '100%',
    },
    iconInner: {
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100%',
        paddingBottom: 16,
    },
    iconGrid: {
        flexWrap: 'wrap',
        paddingHorizontal: 8,
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'space-around',
    },
});
