import React, { Fragment, useMemo } from 'react';
import { StyleSheet, View } from 'react-native';
import { Surface, Text, useTheme } from 'react-native-paper';
import { getCurrentTime } from 'react-native-use-timestamp';
const styles = StyleSheet.create({
    container: {
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        width: '100%',
        height: 100,
        paddingHorizontal: 16,
        elevation: 1,
    },
    component: {
        flexDirection: 'column',
        marginRight: 8,
        marginLeft: 8,
        minWidth: 50,
    },
    number: {
        fontSize: 22,
    },
    label: {},
});
export function timeToCountdown(destination, now) {
    return destination - now;
}
const MS_PER_SECOND = 1000;
const MS_PER_MINUTE = MS_PER_SECOND * 60;
const MS_PER_HOUR = MS_PER_MINUTE * 60;
const MS_PER_DAY = MS_PER_HOUR * 24;
function getTimeDiffComponents(diffInMs) {
    const days = Math.floor(diffInMs / MS_PER_DAY);
    diffInMs = diffInMs % MS_PER_DAY;
    const hours = Math.floor(diffInMs / MS_PER_HOUR);
    diffInMs = diffInMs % MS_PER_HOUR;
    const minutes = Math.floor(diffInMs / MS_PER_MINUTE);
    diffInMs = diffInMs % MS_PER_MINUTE;
    const seconds = Math.floor(diffInMs / MS_PER_SECOND);
    return { days, hours, minutes, seconds };
}
function pluralize(count, singular, plural) {
    return count === 1 ? singular : plural;
}
export function TimeToDestination({ now, date: destination, isMobileView, }) {
    const currentTime = now || getCurrentTime();
    const { roundness } = useTheme();
    const diffInMs = timeToCountdown(destination, currentTime);
    const { days, hours, minutes, seconds } = useMemo(() => getTimeDiffComponents(diffInMs), [diffInMs]);
    if (diffInMs < 0) {
        return null;
    }
    return (React.createElement(Fragment, null,
        React.createElement(Surface, { style: [
                styles.container,
                {
                    borderRadius: isMobileView ? 0 : roundness,
                    marginTop: isMobileView ? 0 : 24,
                },
            ] },
            React.createElement(View, { style: { flexDirection: 'row' } },
                days >= 1 ? (React.createElement(View, { style: styles.component },
                    React.createElement(Text, { style: styles.number }, days),
                    React.createElement(Text, { style: styles.label }, pluralize(days, 'day', 'days')))) : null,
                React.createElement(View, { style: styles.component },
                    React.createElement(Text, { style: styles.number }, hours),
                    React.createElement(Text, { style: styles.label }, pluralize(hours, 'hour', 'hours'))),
                React.createElement(View, { style: styles.component },
                    React.createElement(Text, { style: styles.number }, minutes),
                    React.createElement(Text, { style: styles.label }, pluralize(minutes, 'minute', 'minutes'))),
                React.createElement(View, { style: styles.component },
                    React.createElement(Text, { style: styles.number }, seconds),
                    React.createElement(Text, { style: styles.label }, pluralize(seconds, 'second', 'seconds')))))));
}
