import { __rest } from "tslib";
import { ActionGrid, ActionList } from '@introcloud/blocks';
import { createElement, useCallback } from 'react';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
export function ActionsBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const { value: { label, items } } = block, _a = block.options, { mode, round, roundBelowLabel } = _a, options = __rest(_a, ["mode", "round", "roundBelowLabel"]), { previous, next } = block;
    switch (mode) {
        case 'list': {
            return createElement(ActionList, Object.assign(Object.assign({}, options), { title: label, actions: items, round: round
                    ? {
                        top: (roundBelowLabel && !!label) ||
                            (!extendsFullyToBottom(previous) && !label),
                        bottom: !extendsFullyToTop(next),
                    }
                    : undefined, renderFallback }));
        }
        case 'image-grid': {
            return createElement(ActionGrid, Object.assign(Object.assign({}, options), { title: label, actions: items, mode: 'images', size: 'large', renderFallback }));
        }
        case 'icon-grid': {
            return createElement(ActionGrid, Object.assign(Object.assign({}, options), { title: label, actions: items, mode: 'icons', size: 'small', renderFallback }));
        }
    }
    return null;
}
