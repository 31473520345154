import { __rest } from "tslib";
import { useQuery } from 'react-query';
import { useBlockData } from '../BlockData';
export function useBlockDataSponsorById(sponsorId, _a = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    const { getSponsorById } = useBlockData();
    const _b = useQuery(['blocks', 'sponsor', sponsorId], () => getSponsorById(sponsorId), Object.assign({ staleTime: 60 * 1000, enabled: enabled && !!sponsorId, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false }, options)), { data: sponsor, error } = _b, others = __rest(_b, ["data", "error"]);
    return Object.assign({ data: sponsor, loading: others.isLoading, refreshing: others.isFetching && !others.isLoading, error, reload: others.refetch }, others);
}
export function useBlockDataSponsorByKind(kind, cacheKey, _a = {}) {
    var { enabled = true } = _a, options = __rest(_a, ["enabled"]);
    const { getSponsor } = useBlockData();
    const _b = useQuery(['blocks', 'sponsor', kind, cacheKey], () => getSponsor(kind), Object.assign({ enabled: enabled && !!kind && !!cacheKey, refetchOnMount: false, refetchOnReconnect: false, refetchOnWindowFocus: false }, options)), { data: sponsor, error } = _b, others = __rest(_b, ["data", "error"]);
    return Object.assign({ data: sponsor, loading: others.isLoading, refreshing: others.isFetching && !others.isLoading, error, reload: others.refetch }, others);
}
