import { Spacer, TextBlock, TextButton } from '@introcloud/blocks';
import { createElement } from 'react';
import { View } from 'react-native';
import { Card, List } from 'react-native-paper';
import { localeDateString, localeTimeString } from 'react-native-time-helpers';
const DEFAULT_STYLE = {
    width: '100%',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
};
export function NewsBlock(block) {
    const style = DEFAULT_STYLE;
    // TODO suspend
    return createElement(View, {
        key: 'surface',
        style,
    }, [
        createElement(List.Subheader, { key: 'header' }, 'Latest news'),
        createElement(Card, {
            key: 'card',
            elevation: 1,
            children: [
                createElement(Card.Title, {
                    key: 'title',
                    title: 'Title of the last news item',
                    subtitle: `${localeDateString(new Date(), true, false)}, ${localeTimeString(new Date())}`,
                }),
                createElement(TextBlock, {
                    key: 'text',
                    text: 'This is a placeholder. This block is hidden until there is at least one news-item. From that point forward, it always shows the last news item.',
                    round: { bottom: true },
                }),
            ],
        }),
        createElement(Spacer, { key: 'spacer', space: 1 }),
        createElement(TextButton, {
            key: 'link',
            style: { position: 'absolute', right: 4, top: 4 },
            children: 'See older',
        }),
    ]);
}
