"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.submitApplicationEventGoal = exports.fetchApplicationEventGoalRanking = exports.fetchApplicationEventGoals = void 0;
var tslib_1 = require("tslib");
var fetch_media_1 = require("fetch-media");
var core_1 = require("./core");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
function fetchApplicationEventGoals(mainEventId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!mainEventId) {
                        throw new errors_1.ApiClientError('Main-event id missing');
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-goal/list/" + mainEventId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationEventGoals = fetchApplicationEventGoals;
function fetchApplicationEventGoalRanking(mainEventId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!mainEventId) {
                        throw new errors_1.ApiClientError('Main-event id missing');
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-goal/ranking/" + mainEventId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationEventGoalRanking = fetchApplicationEventGoalRanking;
function submitApplicationEventGoal(goalId, kind, data, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    if (!goalId) {
                        throw new errors_1.ApiClientError('goal id missing');
                    }
                    if (!(kind === 'textarea' || kind === 'input')) return [3 /*break*/, 2];
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-goal-submission/" + goalId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                                contentType: 'application/json; charset=utf-8',
                            },
                            method: 'POST',
                            signal: signal,
                            debug: debug,
                            body: {
                                value: data,
                            },
                        })];
                case 1: return [2 /*return*/, _a.sent()];
                case 2:
                    if (!(kind === 'image' || kind === 'video')) return [3 /*break*/, 4];
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-goal-submission/" + goalId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            method: 'POST',
                            signal: signal,
                            debug: debug,
                            body: data,
                        })];
                case 3: return [2 /*return*/, _a.sent()];
                case 4: return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-goal-submission/" + goalId, {
                        headers: {
                            accept: ACCEPT,
                            authorization: authorization,
                            contentType: 'application/json; charset=utf-8',
                        },
                        method: 'POST',
                        signal: signal,
                        debug: debug,
                        body: {
                            answerId: data,
                        },
                    })];
                case 5:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.submitApplicationEventGoal = submitApplicationEventGoal;
