import { MemoryValue, useMutableMemoryValue } from 'expo-use-memory-value';
import React, { useCallback, Fragment } from 'react';
import { StyleSheet, View, } from 'react-native';
import { Card, IconButton, Paragraph, Surface, useTheme, Title, Headline, } from 'react-native-paper';
import { rgba } from './utils';
const TRANSLATIONS_ACTIVE = new MemoryValue(false);
function useTranslation(original, translation) {
    const [useTranslation, translate] = useTranslationAction();
    if (!original) {
        return [translation || '', translate];
    }
    if (!translation) {
        return [original, translate];
    }
    return [
        useTranslation ? translation || original : original || translation,
        translate,
    ];
}
export function useTranslationAction() {
    const [isActive, setActive] = useMutableMemoryValue(TRANSLATIONS_ACTIVE);
    const translate = useCallback(() => {
        setActive(!isActive);
    }, [isActive, setActive]);
    return [!!isActive, translate];
}
export function TextBlock({ text, translation, style, round, options, textStyle, renderFallback, }) {
    const [value, translate] = useTranslation(text, translation);
    const { roundness } = useTheme();
    if (!value) {
        return renderFallback ? renderFallback() : null;
    }
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const borderStyle = {
        borderTopLeftRadius: topRadius,
        borderTopRightRadius: topRadius,
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    };
    return (React.createElement(Surface, { style: [styles.wrapper, style, borderStyle] },
        React.createElement(View, { style: [styles.contentWrapper, borderStyle, { overflow: 'hidden' }] },
            React.createElement(Card.Content, { style: styles.content, accessibilityLiveRegion: translation ? 'polite' : undefined },
                React.createElement(TextContent, { content: value, size: options === null || options === void 0 ? void 0 : options.size, style: textStyle })),
            !!translation && !(options === null || options === void 0 ? void 0 : options.hideTranslatable) ? (React.createElement(IconButton, { accessibilityLabel: "Translate content", icon: "translate", onPress: translate, style: styles.button, disabled: options && options.translatable === false })) : null)));
}
function TextContent({ content, size, style, }) {
    switch (size || 'paragraph') {
        case 'paragraph': {
            return (React.createElement(Fragment, null, content.split('\n').map((paragraph, index) => (React.createElement(Paragraph, { key: index, style: [styles.paragraph, style] }, paragraph)))));
        }
        case 'title': {
            return React.createElement(Title, { style: [styles.title, style] }, content);
        }
        case 'headline': {
            return React.createElement(Headline, { style: [styles.title, style] }, content);
        }
    }
}
const styles = StyleSheet.create({
    title: { margin: 0 },
    wrapper: { elevation: 1, paddingBottom: 4, paddingTop: 12 },
    contentWrapper: { flexDirection: 'row', width: '100%' },
    content: { flex: 1 },
    paragraph: { marginBottom: 10, lineHeight: 22 },
    dragging: {
        elevation: 8,
    },
    editing: {
        borderWidth: 2,
        borderColor: rgba([255, 127, 127, 0.3]),
    },
    button: { marginHorizontal: 12, marginVertical: 0 },
});
