import { ResponsiveImage } from '@introcloud/blocks';
import { createElement, useCallback } from 'react';
import { Surface, useTheme } from 'react-native-paper';
import { useErrorHandler } from '../ErrorHandler';
import { extendsFullyToBottom, extendsFullyToTop } from '../utils/hasSurface';
export function ImageBlock(block) {
    var _a;
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const { roundness } = useTheme();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const actualRatio = typeof block.options.ratio === 'number'
        ? block.options.ratio
        : block.options.ratio[0] / block.options.ratio[1];
    if (!block.value.src) {
        return renderFallback();
    }
    const previousHasBottomConnection = extendsFullyToBottom(block.previous);
    const nextHasTopConnection = extendsFullyToTop(block.next);
    const borderStyle = ((_a = block.options) === null || _a === void 0 ? void 0 : _a.round) && roundness
        ? {
            borderTopLeftRadius: previousHasBottomConnection ? 0 : roundness,
            borderTopRightRadius: previousHasBottomConnection ? 0 : roundness,
            borderBottomLeftRadius: nextHasTopConnection ? 0 : roundness,
            borderBottomRightRadius: nextHasTopConnection ? 0 : roundness,
        }
        : undefined;
    return createElement(Surface, {
        style: [borderStyle, { elevation: 1 }],
        children: createElement(ResponsiveImage, Object.assign(Object.assign(Object.assign({}, block.value), block.options), { ratio: actualRatio, renderFallback, round: {
                top: block.options.round && !previousHasBottomConnection,
                bottom: block.options.round && !nextHasTopConnection,
            } })),
    });
}
