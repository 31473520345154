import { __rest } from "tslib";
import { AccentButton, PrimaryButton, TextButton, useBlockData, useBlockDataCheckinStatus, useBlockDataEvent, } from '@introcloud/blocks';
import { createElement, useCallback, useState, memo, Fragment, useMemo, } from 'react';
import { View, Platform } from 'react-native';
import { ActivityIndicator, Avatar, Caption, Dialog, Paragraph, Portal, ProgressBar, Surface, useTheme, Title, } from 'react-native-paper';
import { localeTimeString, localeDateString } from 'react-native-time-helpers';
import { useTimestamp, useInaccurateTimestamp, } from 'react-native-use-timestamp';
import { useErrorHandler } from '../ErrorHandler';
import { usePageData } from '../PageData';
import { extendsFullyToBottom, extendsFullyToTop, hasSurfacePadding, } from '../utils/hasSurface';
import { isHeading } from '../utils/isHeading';
export function CheckInBlock(block) {
    const { pageEvent } = usePageData();
    const eventForCheckinId = (block.value.event === 'auto' ? pageEvent === null || pageEvent === void 0 ? void 0 : pageEvent._id : block.value.event) || '';
    const { data, change, error, refreshing, loading, } = useBlockDataCheckinStatus(eventForCheckinId);
    const { roundness } = useTheme();
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const target = block.value.target || 'arrive';
    const active = !!(data && data.id === target);
    if (!eventForCheckinId) {
        return renderFallback();
    }
    if (error && !data) {
        return renderFallback();
    }
    const { value: { label }, options: { spacing, round, mode }, next, previous, dragging, } = block;
    const roundTop = round && !extendsFullyToBottom(previous);
    const roundBottom = round && !extendsFullyToTop(next);
    const topRadius = roundTop ? roundness : 0;
    const bottomRadius = roundBottom ? roundness : 0;
    const shouldCollapseMarginTop = !dragging && hasSurfacePadding(previous) && !isHeading(previous);
    const fixedSpace = spacing === undefined ? 2 : spacing;
    const style = fixedSpace
        ? {
            paddingTop: shouldCollapseMarginTop ? 0 : fixedSpace * 8,
            paddingBottom: fixedSpace * 8,
        }
        : undefined;
    return createElement(Surface, {
        style: [
            style,
            {
                elevation: 1,
                borderTopLeftRadius: topRadius,
                borderTopRightRadius: topRadius,
                borderBottomLeftRadius: bottomRadius,
                borderBottomRightRadius: bottomRadius,
            },
        ],
        children: [
            createElement(View, {
                key: 'button',
                style: {
                    marginLeft: 16,
                    marginRight: 'auto',
                },
            }, createElement(EventCheckinButton, {
                disabled: loading || refreshing,
                active,
                change,
                target,
                data,
                label: active ? 'Show check-in' : label || 'Self check-in',
                icon: active ? 'check-circle' : 'check-circle-outline',
                loading: loading || refreshing,
                mode,
                surface: false,
                inset: true,
                eventId: eventForCheckinId,
            })),
        ],
    });
}
function EventCheckinButton(_a) {
    var { eventId, disabled, label, active, target, change, data } = _a, props = __rest(_a, ["eventId", "disabled", "label", "active", "target", "change", "data"]);
    const [showDialog, setShowDialog] = useState(false);
    const hideDialog = useCallback(() => setShowDialog(false), []);
    const onPress = useCallback(() => {
        if (active) {
            setShowDialog(true);
            return;
        }
        change(target).then(() => {
            setShowDialog(true);
        });
    }, [change, active, target]);
    const { data: event, loading } = useBlockDataEvent(eventId);
    // Disable check because the module says it's disabled
    const disableCheckIn = useMemo(() => {
        var _a;
        if (!event || !((_a = event.module) === null || _a === void 0 ? void 0 : _a.application)) {
            return false;
        }
        if (active) {
            return false;
        }
        return !event.module.application.canCheckIn;
    }, [event, active]);
    const startBoundary = (event === null || event === void 0 ? void 0 : event.duration.start.unix) || 0;
    const endBoundary = (event === null || event === void 0 ? void 0 : event.duration.end.unix) || 0;
    return createElement(Fragment, undefined, [
        createElement(TimedCheckInButton, Object.assign(Object.assign({}, props), { onPress, key: 'button', disabled: disabled || loading || disableCheckIn, label,
            startBoundary,
            endBoundary })),
        createElement(CheckInDialog, {
            key: 'dialog',
            visible: showDialog,
            onDismiss: hideDialog,
            data,
            event,
        }),
    ]);
}
function TimedCheckInButton(_a) {
    var { disabled, label, startBoundary, endBoundary } = _a, props = __rest(_a, ["disabled", "label", "startBoundary", "endBoundary"]);
    const now = useInaccurateTimestamp({
        every: Platform.select({ web: 10 * 1000, default: 1500 }),
    });
    const timeDisabled = now < startBoundary || now > endBoundary;
    const timeLabel = timeDisabled
        ? now < startBoundary
            ? 'Please wait...'
            : 'Expired'
        : undefined;
    return createElement(CheckInButton, Object.assign(Object.assign({}, props), { disabled: disabled || timeDisabled, label: timeLabel || label }));
}
function CheckInDialog({ visible, onDismiss, data, event, }) {
    var _a, _b;
    const { roundness } = useTheme();
    const { currentUser } = usePageData();
    const { getImageUrl } = useBlockData();
    const contextName = event === null || event === void 0 ? void 0 : event.name.full;
    const imageUrl = getImageUrl((currentUser === null || currentUser === void 0 ? void 0 : currentUser.image.profile) || '', 'icon_256');
    const initials = [(_a = currentUser === null || currentUser === void 0 ? void 0 : currentUser.name.first) === null || _a === void 0 ? void 0 : _a[0], (_b = currentUser === null || currentUser === void 0 ? void 0 : currentUser.name.last) === null || _b === void 0 ? void 0 : _b[1]]
        .filter(Boolean)
        .join('');
    return createElement(Portal, undefined, createElement(Dialog, {
        style: {
            alignSelf: 'center',
            maxWidth: 320,
            width: '100%',
        },
        visible: visible && data !== null,
        onDismiss,
        children: [
            createElement(Dialog.Content, {
                key: 'content',
                children: data
                    ? createElement(View, { style: { alignItems: 'center' } }, [
                        imageUrl
                            ? createElement(Avatar.Image, {
                                key: 'image',
                                size: 96,
                                source: { uri: imageUrl },
                            })
                            : createElement(Avatar.Text, {
                                key: 'image',
                                size: 96,
                                label: initials,
                            }),
                        contextName &&
                            createElement(Title, {
                                key: 'title',
                                style: { marginTop: 12, textAlign: 'center' },
                                children: contextName,
                            }),
                        visible &&
                            createElement(CheckInTimeIndicator, {
                                key: 'indicator',
                                time: data.put.at.unix,
                            }),
                    ])
                    : createElement(ActivityIndicator, {
                        key: 'animation',
                        style: {
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginTop: 32,
                        },
                    }),
            }),
            createElement(Caption, {
                key: 'relative',
                style: {
                    marginTop: 16,
                    marginLeft: 16,
                    marginRight: 16,
                    textAlign: 'center',
                },
                children: 'The animated bar below indicates an active, valid, check-in.',
            }),
            createElement(ProgressBar, {
                key: 'progress',
                indeterminate: true,
                style: {
                    borderBottomRightRadius: roundness,
                    borderBottomLeftRadius: roundness,
                },
            }),
        ],
    }));
}
function CheckInTimeIndicator({ time }) {
    const timestamp = useTimestamp({ every: 1000 });
    const date = new Date(time);
    const seconds = Math.round((timestamp - time) / 1000);
    return createElement(View, { style: { marginTop: 32 } }, [
        createElement(Paragraph, {
            key: 'time',
            children: `Checked in at ${localeTimeString(date)} on ${localeDateString(date, true)}`,
            style: {
                textAlign: 'center',
            },
        }),
        createElement(Paragraph, {
            key: 'relative',
            children: seconds < 60
                ? `That's ${seconds} seconds ago.`
                : seconds < 60 * 60
                    ? `That's about ${Math.round(seconds / 60)} minutes ago.`
                    : "That's more than an hour ago",
            style: {
                textAlign: 'center',
            },
        }),
    ]);
}
function CheckInButton_(_a) {
    var { mode, label, onPress, loading } = _a, other = __rest(_a, ["mode", "label", "onPress", "loading"]);
    switch (mode) {
        case 'primary': {
            return createElement(PrimaryButton, Object.assign(Object.assign({}, other), { onPress,
                loading, children: label }));
        }
        case 'accent': {
            return createElement(AccentButton, Object.assign(Object.assign({}, other), { onPress,
                loading, children: label }));
        }
        case 'text': {
            return createElement(TextButton, Object.assign(Object.assign({}, other), { onPress,
                loading, children: label }));
        }
        default: {
            return createElement(PrimaryButton, Object.assign(Object.assign({}, other), { onPress,
                loading, children: label }));
        }
    }
}
const CheckInButton = memo(CheckInButton_);
