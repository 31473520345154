"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.fetchApplicationEventAttendance = exports.mutateApplicationEventAttendance = exports.findSubEvents = exports.matchEventOnId = exports.findRegularEvents = exports.findMainEvents = exports.fetchApplicationEvent = exports.fetchEditorEvents = exports.fetchApplicationEvents = exports.fetchPersonalEventById = exports.fetchPublicEvents = void 0;
var tslib_1 = require("tslib");
var fetch_media_1 = require("fetch-media");
var core_1 = require("./core");
var errors_1 = require("./errors");
var ACCEPT = 'application/json';
/**
 *
 * @see {fetchApplicationEvents}
 *
 * @param endpoint
 * @param authorization
 * @param signal
 * @param debug
 */
function fetchPublicEvents(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/public/event/list", {
                        headers: {
                            accept: ACCEPT,
                            authorization: authorization !== null && authorization !== void 0 ? authorization : undefined,
                        },
                        signal: signal,
                        debug: debug,
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchPublicEvents = fetchPublicEvents;
function fetchPersonalEventById(id, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + ("/user/event/" + encodeURIComponent(id) + "/read"), {
                        headers: {
                            accept: ACCEPT,
                            authorization: authorization,
                        },
                        signal: signal,
                        debug: debug,
                    })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchPersonalEventById = fetchPersonalEventById;
function fetchApplicationEvents(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationEvents = fetchApplicationEvents;
function fetchEditorEvents(endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/editor/event", {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchEditorEvents = fetchEditorEvents;
function fetchApplicationEvent(eventId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!eventId) {
                        throw new errors_1.ApiClientError('Missing event id to fetch');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event/" + eventId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationEvent = fetchApplicationEvent;
function findMainEvents(events) {
    return events.filter(function (event) { return event.eventRef.eventId === null; });
}
exports.findMainEvents = findMainEvents;
/**
 * Get the regular listed events
 * @param mainEventId the main event
 * @param events
 */
function findRegularEvents(mainEventId, events) {
    return findSubEvents(mainEventId, events);
}
exports.findRegularEvents = findRegularEvents;
function matchEventOnId(id, events) {
    return events.filter(function (event) { return event._id === id; });
}
exports.matchEventOnId = matchEventOnId;
/**
 * Get the sub events of an event
 * @param eventId event as parent
 * @param events children
 */
function findSubEvents(eventId, events) {
    return events.filter(function (event) { return event.eventRef.eventId === eventId; });
}
exports.findSubEvents = findSubEvents;
function mutateApplicationEventAttendance(eventId, state, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!eventId) {
                        throw new errors_1.ApiClientError('Missing event id to attend');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event/" + eventId + "/attendance/" + state, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.mutateApplicationEventAttendance = mutateApplicationEventAttendance;
function fetchApplicationEventAttendance(eventId, endpoint, authorization, signal, debug) {
    return tslib_1.__awaiter(this, void 0, void 0, function () {
        var result;
        return tslib_1.__generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!eventId) {
                        throw new errors_1.ApiClientError('Missing event id to get attendance for');
                    }
                    if (!endpoint) {
                        throw new errors_1.RequiresDomain();
                    }
                    if (!authorization) {
                        throw new errors_1.RequiresAuthentication();
                    }
                    return [4 /*yield*/, fetch_media_1.fetchMedia(endpoint + "/application/event-guest/" + eventId, {
                            headers: {
                                accept: ACCEPT,
                                authorization: authorization,
                            },
                            signal: signal,
                            debug: debug,
                        })];
                case 1:
                    result = _a.sent();
                    return [2 /*return*/, core_1.typeCheck(result)];
            }
        });
    });
}
exports.fetchApplicationEventAttendance = fetchApplicationEventAttendance;
