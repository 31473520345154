import React, { useCallback, useMemo } from 'react';
import { StyleSheet } from 'react-native';
import { Caption, List, Text } from 'react-native-paper';
import { localeTimeString } from 'react-native-time-helpers';
import { useBlockNavigation } from './BlockNavigation';
import { useBlockSettings } from './BlockSettings';
import { EventIcon } from './EventIcon';
import { localeSmartTimeString } from './utils';
export function EventListItemLocationFocus({ id, title, duration: { start, end }, illustration, locationIds, fallbackLocation, loading, tags, }) {
    const { gotoEvent } = useBlockNavigation();
    const { EventListItem: { lines, imageEnabled }, } = useBlockSettings();
    const renderLeft = useCallback((props) => (React.createElement(EventIcon, Object.assign({ loading: loading, tags: tags, illustration: illustration, fallback: true, compact: true }, props))), [illustration, loading, tags]);
    const renderTime = useCallback(() => React.createElement(EventTime, { start: start, end: end }), [
        start,
        end,
    ]);
    const renderLocation = useCallback(() => (React.createElement(EventLocations, { loading: loading, locations: locationIds, fallback: fallbackLocation })), [locationIds, fallbackLocation]);
    return (React.createElement(List.Item, { title: title, left: imageEnabled ? renderLeft : undefined, right: renderTime, description: renderLocation, descriptionNumberOfLines: lines || 3, onPress: () => gotoEvent(id) }));
}
function EventTime({ start, end }) {
    const startDate = useMemo(() => new Date(start || 0), [start]);
    const endDate = useMemo(() => new Date(end || 0), [end]);
    if (!start) {
        return null;
    }
    const startTime = localeTimeString(startDate, false);
    const endTime = localeTimeString(endDate, false);
    if (startTime === endTime || !end) {
        return (React.createElement(Text, { style: styles.time }, localeSmartTimeString(startDate, new Date().getTime())));
    }
    const days = Math.floor((endDate.getTime() - startDate.getTime()) / 1000 / 60 / 60 / 24);
    return (React.createElement(Text, { style: styles.time },
        localeSmartTimeString(startDate, new Date().getTime()),
        " - ",
        endTime,
        React.createElement(Caption, { style: styles.plusDays }, days > 0 ? ` +${days}` : '')));
}
function EventLocations({ locations, fallback, loading, }) {
    if (loading) {
        return React.createElement(Caption, null, "\u00A0");
    }
    // TODO grab locations
    return (React.createElement(Caption, null, (locations && locations.length && locations.join(', ')) ||
        fallback ||
        'Various Locations'));
}
const styles = StyleSheet.create({
    plusDays: {
        textAlignVertical: 'top',
        fontSize: 12,
        position: 'absolute',
        right: 8,
        top: 32,
    },
    item: {
        width: '100%',
    },
    time: {
        marginRight: 8,
        marginLeft: 8,
        alignSelf: 'center',
        fontWeight: 'bold',
    },
});
