import { __rest } from "tslib";
import merge from 'lodash.merge';
import React, { Fragment, useEffect, useMemo, useState } from 'react';
import { StyleSheet, View, Platform } from 'react-native';
import { Divider, List, Searchbar, Surface, useTheme, } from 'react-native-paper';
import { useDestination } from './hooks/useDestination';
export function ActionList({ title, actions, icons, filter, dividers, compact, round, renderFallback, }) {
    const { roundness } = useTheme();
    const topRadius = (round === null || round === void 0 ? void 0 : round.top) ? roundness : 0;
    const bottomRadius = (round === null || round === void 0 ? void 0 : round.bottom) ? roundness : 0;
    const padding = dividers || compact ? 0 : 16;
    const preparedActions = useMemo(() => actions.map((action) => (Object.assign(Object.assign({}, action), { id: Math.random().toString(36) }))), [actions]);
    const [filtered, setFiltered] = useState(() => preparedActions);
    const [query, setQuery] = useState('');
    useEffect(() => {
        const handler = setTimeout(() => {
            setFiltered(filter ? filterActions(query, preparedActions) : preparedActions);
        });
        return () => clearTimeout(handler);
    }, [preparedActions, query]);
    if (actions.length === 0) {
        return renderFallback ? renderFallback() : null;
    }
    const borderRadiusStyle = merge({
        borderBottomLeftRadius: bottomRadius,
        borderBottomRightRadius: bottomRadius,
    }, filter
        ? {}
        : {
            borderTopLeftRadius: topRadius,
            borderTopRightRadius: topRadius,
        });
    return (React.createElement(Fragment, null,
        !!title && (React.createElement(View, { style: { width: '100%' } },
            React.createElement(List.Subheader, null, title))),
        filter && (React.createElement(Fragment, null,
            React.createElement(Searchbar, { style: [
                    styles.search,
                    {
                        borderTopLeftRadius: topRadius,
                        borderTopRightRadius: topRadius,
                    },
                ], textAlign: "auto", inputStyle: styles.searchInput, placeholder: "Filter...", onChangeText: setQuery, value: query }),
            React.createElement(Divider, { style: { height: StyleSheet.hairlineWidth } }))),
        React.createElement(Surface, { style: [
                styles.surface,
                {
                    paddingVertical: padding,
                },
                borderRadiusStyle,
            ] },
            React.createElement(View, { style: [{ overflow: 'hidden', width: '100%' }, borderRadiusStyle] }, filtered.map((_a, index, self) => {
                var { id } = _a, action = __rest(_a, ["id"]);
                return (React.createElement(Fragment, { key: id },
                    React.createElement(PureListItem, Object.assign({ key: id }, action, { icons: icons || false })),
                    dividers && index !== self.length - 1 && React.createElement(Divider, null)));
            })))));
}
function filterActions(query, items) {
    if (query.trim().length === 0) {
        return items;
    }
    const parts = query.trim().toLocaleLowerCase().split(' ');
    return items.filter((item) => {
        const name = item.label.toLocaleLowerCase();
        return name.includes(query) || parts.every((part) => name.includes(part));
    });
}
function ListItem({ icons, label, icon, destination, }) {
    const onPress = useDestination(destination);
    return (React.createElement(List.Item, { left: icons
            ? (props) => (React.createElement(List.Icon, Object.assign({ icon: icon || 'link' }, props, { style: [props.style, styles.icon] })))
            : undefined, title: label, titleStyle: icons ? styles.titleSecond : styles.titleFirst, right: (props) => React.createElement(List.Icon, Object.assign({ icon: "chevron-right" }, props)), onPress: onPress }));
}
const PureListItem = React.memo(ListItem);
const styles = StyleSheet.create({
    search: {
        paddingLeft: 4,
        elevation: 1,
        borderRadius: 0,
    },
    searchInput: { marginLeft: 12 },
    surface: { elevation: 1 },
    icon: { marginLeft: 0 },
    titleFirst: { paddingLeft: 0 },
    titleSecond: { paddingLeft: Platform.OS === 'web' ? 8 : 0 },
});
