"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.typeCheck = exports.fetchMedia = exports.setApiKey = void 0;
var fetch_media_1 = require("fetch-media");
Object.defineProperty(exports, "fetchMedia", { enumerable: true, get: function () { return fetch_media_1.fetchMedia; } });
var errors_1 = require("./errors");
var ACCEPT_ERRORS = 'application/vnd.tactile.errors.v1+json; q=0.2';
fetch_media_1.setDefaultAccept(ACCEPT_ERRORS, fetch_media_1.ACCEPT_PROBLEM);
function setApiKey(key) {
    fetch_media_1.setDefaultHeaders({ 'Authorization-Api-Key': key });
}
exports.setApiKey = setApiKey;
// TODO remove
setApiKey('D3VZLP4HBV6QNHDC881H9OIDMHCIUKCQQAXJAIU5YRELXBSTALWWHESBRGSLXJWNVWREW3ZUVRP6LRYP291QOUPPEAEQ8C5KY12USAX9LBENQ8XYIACUYOO1PYKIOMC4');
// eslint-disable-next-line @typescript-eslint/ban-types
function typeCheck(result) {
    if (typeof result === 'object' && result) {
        if (result.hasOwnProperty('data')) {
            return result.data;
        }
        throw new errors_1.FailedTypeCheck('Expected data key, got ' + Object.keys(result));
    }
    throw new errors_1.FailedTypeCheck('Expected object, got a ' + typeof result);
}
exports.typeCheck = typeCheck;
