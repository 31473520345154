import React, { createContext, useContext, useMemo } from 'react';
export const DEFAULT_BLOCK_NAVIGATION = {
    gotoEvent: (id) => console.log('Navigate to event', id),
    gotoLocation: (id) => console.log('Navigate to location', id),
    gotoInfo: (id) => console.log('Navigate to info', id),
    gotoLive: (id) => console.log('Navigate to live', id),
    gotoChat: (id) => console.log('Navigate to chat', id),
    gotoExternal: (url) => console.log('Navigate to url', url),
    gotoTab: (id) => console.log('Navigate to tab', id),
    gotoInternal: (deeplink) => console.log('Navigate to deeplink', deeplink),
    gotoTimeslot: (id) => console.log('Navigate to timeslot page', id),
    gotoDirections: (destination, mode) => console.log('Navigate to directions', destination, mode),
    emit: (value) => {
        throw new Error('Emitting is only acceptable in custom contexts');
    },
};
const BlockNavigationContext = createContext(DEFAULT_BLOCK_NAVIGATION);
export const BlockNavigationProvider = BlockNavigationContext.Provider;
export function useBlockNavigation() {
    return useContext(BlockNavigationContext);
}
export function useHasProvidedBlockNavigation() {
    return useBlockNavigation() !== DEFAULT_BLOCK_NAVIGATION;
}
export function ProvideBlockNavigation({ navigation, children, }) {
    const merged = useMemo(() => (Object.assign(Object.assign({}, DEFAULT_BLOCK_NAVIGATION), navigation)), [navigation]);
    return (React.createElement(BlockNavigationProvider, { value: merged }, children));
}
