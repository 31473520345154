import { __rest } from "tslib";
import { Divider, PrimaryButton, useBlockData, useBlockNavigation, } from '@introcloud/blocks';
import React, { useCallback, useEffect, useState } from 'react';
import { View } from 'react-native';
import { List, Surface, useTheme } from 'react-native-paper';
import { useInaccurateTimestamp } from 'react-native-use-timestamp';
import { useErrorHandler } from '../ErrorHandler';
import { usePageData } from '../PageData';
import { extendsFullyToBottom, extendsFullyToTop, hasSurfaceAtBottom, hasSurfaceAtTop, } from '../utils/hasSurface';
export function VideoStreamBlock(block) {
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const src = block.value.video.src;
    if (!src) {
        return renderFallback();
    }
    return React.createElement(RemoteVideoStreamBlock, Object.assign({}, block, { videoId: src }));
}
function RemoteVideoStreamBlock(_a) {
    var { videoId } = _a, block = __rest(_a, ["videoId"]);
    const { value, options, id, previous, next } = block, props = __rest(block, ["value", "options", "id", "previous", "next"]);
    const { getVideoPreviewById } = useBlockData();
    const { top, bottom } = (options === null || options === void 0 ? void 0 : options.dividers) || { top: false, bottom: false };
    const realTop = top && hasSurfaceAtBottom(previous);
    const realBottom = bottom && hasSurfaceAtTop(next);
    const dividers = { top: realTop, bottom: realBottom };
    const roundTop = !!(options === null || options === void 0 ? void 0 : options.round) && !extendsFullyToBottom(previous);
    const roundBottom = !!(options === null || options === void 0 ? void 0 : options.round) && !extendsFullyToTop(next);
    const round = { top: roundTop, bottom: roundBottom };
    const { handleEmpty, renderEmpty } = useErrorHandler();
    const renderFallback = useCallback(() => (handleEmpty ? renderEmpty(block) : null), [handleEmpty, renderEmpty, block]);
    const [videoPreview, setVideoPreview] = useState(undefined);
    const [error, setError] = useState(null);
    useEffect(() => {
        let stillCareAboutThis = true;
        getVideoPreviewById(videoId)
            .then((result) => stillCareAboutThis && setVideoPreview(result))
            .catch((error) => stillCareAboutThis && setError(error));
        return () => {
            stillCareAboutThis = false;
        };
    }, [videoId]);
    // This will be captured by the error boundary, but still report
    if (error && !videoPreview) {
        return renderFallback();
    }
    // Generic way to say to wait
    if (!videoPreview || !videoPreview.start) {
        return (React.createElement(PleaseWaitForStream, { surface: options === null || options === void 0 ? void 0 : options.surface, dividers: dividers, round: round }));
    }
    // We know when to gate the user!
    return (React.createElement(GatedVideoStreamBlock, Object.assign({}, props, { value: value, options: Object.assign(Object.assign({}, (options || {})), { dividers }), round: round, id: id, start: videoPreview.start, end: videoPreview.end })));
}
function GatedVideoStreamBlock({ value, options, round, start, end, }) {
    const timestamp = useInaccurateTimestamp({ every: 10 * 1000 });
    // Has not started yet
    if (start && start.unix > timestamp) {
        return (React.createElement(PleaseWaitForStream, { timeLeftInMilliseconds: start.unix - timestamp, surface: options === null || options === void 0 ? void 0 : options.surface, dividers: options === null || options === void 0 ? void 0 : options.dividers, round: round }));
    }
    // Has ended
    if (end && end.unix < timestamp) {
        return (React.createElement(PastLiveStream, { surface: options === null || options === void 0 ? void 0 : options.surface, dividers: options === null || options === void 0 ? void 0 : options.dividers, round: round }));
    }
    return (React.createElement(ActiveLiveStream, Object.assign({}, value, { surface: options === null || options === void 0 ? void 0 : options.surface, dividers: options === null || options === void 0 ? void 0 : options.dividers, round: round })));
}
// TODO: fetch value of this block from API
function PleaseWaitForStream({ timeLeftInMilliseconds, surface, dividers, round, }) {
    const knowsWhen = timeLeftInMilliseconds !== undefined;
    const { roundness } = useTheme();
    const right = useCallback((props) => (React.createElement(PrimaryButton, Object.assign({ disabled: true }, props, { style: Object.assign(Object.assign({}, props.style), { marginVertical: 'auto', alignSelf: 'center' }) }), "Please wait...")), []);
    const contents = (React.createElement(List.Item, { title: "Live stream", description: knowsWhen
            ? 'This stream will be live ' + timeTo(timeLeftInMilliseconds)
            : 'Please wait', descriptionNumberOfLines: 2, right: right }));
    const borderStyle = {
        borderTopLeftRadius: round.top ? roundness : 0,
        borderTopRightRadius: round.top ? roundness : 0,
        borderBottomLeftRadius: round.bottom ? roundness : 0,
        borderBottomRightRadius: round.bottom ? roundness : 0,
    };
    return surface ? (React.createElement(Surface, { style: [
            borderStyle,
            {
                elevation: 1,
            },
        ] },
        React.createElement(View, { style: [borderStyle, { width: '100%', overflow: 'hidden' }] },
            (dividers === null || dividers === void 0 ? void 0 : dividers.top) ? React.createElement(Divider, null) : null,
            contents,
            (dividers === null || dividers === void 0 ? void 0 : dividers.bottom) ? React.createElement(Divider, null) : null))) : (contents);
}
function timeTo(duration) {
    const secondsLeft = duration / 1000;
    if (secondsLeft < 10) {
        return 'in a few seconds';
    }
    const minutesLeft = secondsLeft / 60;
    if (minutesLeft < 1) {
        return 'in less than a minute';
    }
    const hoursLeft = minutesLeft / 60;
    if (hoursLeft < 1) {
        return `in about ${Math.ceil(minutesLeft)} minutes`;
    }
    const daysLeft = hoursLeft / 24;
    if (daysLeft < 1) {
        return `in about ${Math.ceil(hoursLeft)} hours`;
    }
    if (daysLeft < 7) {
        return `in ${Math.ceil(daysLeft)} days`;
    }
    return 'more than a week from now';
}
function PastLiveStream({ surface, dividers, round, }) {
    const { roundness } = useTheme();
    const contents = (React.createElement(List.Item, { title: "Live stream", description: "This stream has ended and there is no recording.", descriptionNumberOfLines: 2 }));
    const borderStyle = {
        borderTopLeftRadius: round.top ? roundness : 0,
        borderTopRightRadius: round.top ? roundness : 0,
        borderBottomLeftRadius: round.bottom ? roundness : 0,
        borderBottomRightRadius: round.bottom ? roundness : 0,
    };
    return surface ? (React.createElement(Surface, { style: [
            borderStyle,
            {
                elevation: 1,
            },
        ] },
        React.createElement(View, { style: [
                borderStyle,
                {
                    overflow: 'hidden',
                    width: '100%',
                },
            ] },
            (dividers === null || dividers === void 0 ? void 0 : dividers.top) ? React.createElement(Divider, null) : null,
            contents,
            (dividers === null || dividers === void 0 ? void 0 : dividers.bottom) ? React.createElement(Divider, null) : null))) : (contents);
}
function ActiveLiveStream({ surface, dividers, round, chat, }) {
    const { roundness } = useTheme();
    const { gotoLive } = useBlockNavigation();
    const { page } = usePageData();
    const onPress = useCallback(() => gotoLive((page === null || page === void 0 ? void 0 : page._id) || ''), []);
    const right = useCallback((props) => (React.createElement(PrimaryButton, { onPress: onPress, style: Object.assign(Object.assign({}, props.style), { marginVertical: 'auto', alignSelf: 'center' }) }, "Join")), []);
    const contents = (React.createElement(List.Item, { title: "Live stream", description: "This stream is live now!", descriptionNumberOfLines: 2, onPress: onPress, right: right }));
    return surface ? (React.createElement(Surface, { style: {
            elevation: 1,
            borderTopLeftRadius: round.top ? roundness : 0,
            borderTopRightRadius: round.top ? roundness : 0,
            borderBottomLeftRadius: round.bottom ? roundness : 0,
            borderBottomRightRadius: round.bottom ? roundness : 0,
        } },
        (dividers === null || dividers === void 0 ? void 0 : dividers.top) ? React.createElement(Divider, null) : null,
        contents,
        (dividers === null || dividers === void 0 ? void 0 : dividers.bottom) ? React.createElement(Divider, null) : null)) : (contents);
}
function RecordedLiveStream({ surface, dividers, round, }) {
    const { roundness } = useTheme();
    const { gotoLive } = useBlockNavigation();
    const { page } = usePageData();
    const onPress = useCallback(() => gotoLive((page === null || page === void 0 ? void 0 : page._id) || ''), []);
    const right = useCallback((props) => (React.createElement(PrimaryButton, { style: Object.assign(Object.assign({}, props.style), { marginVertical: 'auto', alignSelf: 'center' }), onPress: onPress }, "Watch")), []);
    const contents = (React.createElement(List.Item, { title: "Live stream recording", description: "Watch this previously recorded live stream.", descriptionNumberOfLines: 2, onPress: onPress, right: right }));
    const borderStyle = {
        borderTopLeftRadius: round.top ? roundness : 0,
        borderTopRightRadius: round.top ? roundness : 0,
        borderBottomLeftRadius: round.bottom ? roundness : 0,
        borderBottomRightRadius: round.bottom ? roundness : 0,
    };
    return surface ? (React.createElement(Surface, { style: [
            borderStyle,
            {
                elevation: 1,
            },
        ] },
        React.createElement(View, { style: [
                borderStyle,
                {
                    overflow: 'hidden',
                    width: '100%',
                },
            ] },
            (dividers === null || dividers === void 0 ? void 0 : dividers.top) ? React.createElement(Divider, null) : null,
            contents,
            (dividers === null || dividers === void 0 ? void 0 : dividers.bottom) ? React.createElement(Divider, null) : null))) : (contents);
}
